import { Box, Link, styled, Typography } from "@mui/material";

const CabalGroupRowContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  border: `1px solid ${theme.palette.border.dark}`,
  background: theme.palette.surface.silver,
  borderRadius: theme.spacing(1.5),
  gap: "4px",
  maxWidth: "100%",
}));

const OwnerImage = styled("img")(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.border.dark}`,
  objectFit: "cover",
  objectPosition: "center",
  background: theme.palette.surface.container,
}));

const OwnerImageSmall = styled("img")(({ theme }) => ({
  width: 30,
  height: 30,
  borderRadius: "8px",
  border: `1px solid ${theme.palette.border.dark}`,
  objectFit: "cover",
  objectPosition: "center",
  background: theme.palette.surface.container,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  all: "unset",
  cursor: "pointer",
  maxWidth: "100%",
}));

const PerformanceHeaderText = styled(Typography)(({ theme }) => ({
  width: "120px",
  textAlign: "right",
  [theme.breakpoints.up("md")]: {
    width: "110px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90px",
  },
}));

export { CabalGroupRowContainer, OwnerImage, StyledLink, PerformanceHeaderText, OwnerImageSmall };
