import axios from "axios";
import { getAccessToken, refreshAccessToken, onRefreshed, addRefreshSubscriber } from "./auth";

let isRefreshing = false;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  //   withCredentials: true,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    console.debug("Request", config, token);
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.debug("Error response", originalRequest, error.response, error.response.status);

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newAccessToken = await refreshAccessToken();
          isRefreshing = false;
          onRefreshed(newAccessToken);
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          isRefreshing = false;
          return Promise.reject(refreshError);
        }
      } else {
        return new Promise((resolve) => {
          addRefreshSubscriber((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve(axiosInstance(originalRequest));
          });
        });
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
