import { useEffect, useRef, useState } from "react";
import axiosService from "services/axios";
import useToastStore from "store/toast-store/useToastStore";

export const useRedeemCode = () => {
  const [isRedeemed, setIsRedeemed] = useState(false);
  const [isCheckingRedeemed, setIsCheckingRedeemed] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const isCheckingRedeemedRef = useRef(false);
  const { showToast } = useToastStore();

  useEffect(() => {
    const checkRedeemedCode = async () => {
      try {
        isCheckingRedeemedRef.current = true;
        setIsCheckingRedeemed(true);
        if (localStorage.getItem("redeemedCode")) {
          const { res }: any = await axiosService.redeemCode(
            localStorage.getItem("redeemedCode") as string,
          );
          if (res.status >= 200 && res.status < 300) {
            setIsRedeemed(true);
          } else {
            setIsRedeemed(false);
          }
        } else {
          setIsRedeemed(false);
        }
      } catch (error) {
        console.error("Error while redeeming code", error);
        setIsRedeemed(false);
      } finally {
        setIsChecked(true);
        isCheckingRedeemedRef.current = false;
        setIsCheckingRedeemed(false);
      }
    };
    if (!isChecked && !isCheckingRedeemedRef.current && !isRedeemed) {
      checkRedeemedCode();
    }
  }, [localStorage.getItem("redeemedCode")]);

  const redeemCode = async (code: string) => {
    let message;
    try {
      setIsCheckingRedeemed(true);
      showToast({ variant: "info", message: "Checking code..." });

      const { res }: any = await axiosService.redeemCode(code);
      message = res.message;

      if (res.status >= 200 && res.status < 300) {
        showToast({ variant: "success", message, duration: 5000 });
        localStorage.setItem("redeemedCode", code);
        setIsRedeemed(true);
      } else {
        showToast({ variant: "error", message, duration: 5000 });
        localStorage.removeItem("redeemedCode");
        setIsRedeemed(false);
      }
    } catch (error) {
      console.error("Invalid code", error);
      showToast({ variant: "error", message, duration: 5000 });
      setIsRedeemed(false);
    } finally {
      setIsCheckingRedeemed(false);
    }
  };

  return {
    isCheckingRedeemed,
    isRedeemed,
    redeemCode,
  };
};
