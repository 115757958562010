import { Typography, useTheme } from "@mui/material";

import { Box } from "@mui/material";
import { CabalDetails } from "types";
import CoinAvatar from "components/CoinAvatar";
import { PercentageBrick } from "components/PercentageBrick";

import { TimeToNow } from "components/TimeToNow";
import { Chain } from "types";

const FeaturedTokenCall = (params: { cabalCall: CabalDetails["cabalCall"] }) => {
  const theme = useTheme();
  const { cabalCall } = params;
  if (!("name" in cabalCall)) {
    return <Typography variant="title-h3">TBC</Typography>;
  }

  const { symbol, iconUri, chain, createdAt, performance } = cabalCall;

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1.5), width: "100%" }}>
      <Box
        sx={{
          minWidth: "60px",
          width: "100%",
          display: "flex",
          gap: theme.spacing(1.5),
          alignItems: "center",
        }}>
        <Typography
          variant="title-h3"
          sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
          {symbol}
        </Typography>
      </Box>
      <Box sx={{ width: "80px", paddingX: theme.spacing(1) }}>
        {performance !== undefined && <PercentageBrick percentage={performance} />}
      </Box>
      <Box sx={{ width: "80px", textAlign: "right" }}>
        <Typography
          variant="body-sm"
          color={theme.palette.text.secondary}
          sx={{
            whiteSpace: "nowrap",
          }}>
          <TimeToNow time={createdAt} />
        </Typography>
      </Box>
    </Box>
  );
};

export default FeaturedTokenCall;
