import React, { forwardRef, useRef, useState, ChangeEvent, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import {
  CabalIcon,
  CreateCabalButtonWrapper,
  Curve,
  CurveSteepnessHeaderWrapper,
  CurveSteepnessOption,
  CurveSteepnessOptionRadio,
  CurveSteepnessOptionRadioContainer,
  CurveSteepnessOptionsWrapper,
  CurveSteepnessOptionText,
  HeaderLogo,
  StyledInput,
  StyledLabel,
  StyledOption,
  StyledSelect,
  UploadButtonWrapper,
  UploadImageContainer,
  ViewButtonWrapper,
} from "./styles";
import { RetroButton } from "components/RetroButton";
import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";
import { SteepnessPreview } from "./steepnessPreview/SteepnessPreview";
import { useNetwork } from "lib/hooks/useNetwork";
import axiosService from "services/axios";
import { CreateCabalError, CreateCabalSuccess } from "./createCabalModal";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { ROUTES } from "consts";
import { PreviewChart } from "components/Chart/PreviewChart";
import useToastStore from "store/toast-store/useToastStore";
import { useQuery } from "@tanstack/react-query";
import { Chain } from "types";
import { WalletAddresses } from "store/user-store-v2";
import { DepositModal, TokenType } from "components/Deposit";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import CabalTownLogo from "../../assets/icons/cabal-town-logo.png";
import { useTheme } from "@mui/material";
import { OTP } from "components/OTP";
import { LoadingView } from "components/LoadingView";
import { useRedeemCode } from "hooks/useRedeemCode";

const initialData = {
  Casual: require("./data/casual.json"),
  Standard: require("./data/standard.json"),
  Exclusive: require("./data/exclusive.json"),
};

// Define the ref type
type CreateCabalData = {
  cabalName: string;
  cabalSize: number;
  cabalImageUrl: string;
};
export interface CreateCabalRef {
  getValue: () => CreateCabalData;
  deployContract: () => Promise<void>;
}

export type Steepness = "Casual" | "Standard" | "Exclusive";
export type Language = "English" | "Chinese" | "Korean" | "Russian";

type CreateCabalProps = {};

const CHAIN_NATIVE_TOKEN_MAP: Record<Chain, TokenType> = {
  solana: "SOL",
  ethereum: "ETH",
  ton: "TON",
  base: "BASE",
};

const tonnetworks = {
  mainnet: "ton",
  testnet: "ton_testnet",
};

const SteepnessRR = {
  Casual: "7500",
  Standard: "420",
  Exclusive: "190",
};

const SteepnessMaxMemberPreview = {
  Casual: "2000",
  Standard: "200",
  Exclusive: "100",
};

const LanguageShortForm = {
  English: "en",
  Chinese: "zh_hk",
  Korean: "ko",
  Russian: "ru",
};

const CHAIN_WALLET_MAP: Record<Chain, keyof WalletAddresses> = {
  solana: "solAddress",
  ethereum: "ethAddress",
  ton: "tonAddress",
  base: "ethAddress",
};

export const CreateCabalV2 = forwardRef<CreateCabalRef, CreateCabalProps>((props, ref) => {
  const { tgUserId, tgUserName, walletAddresses, tgPhotoUrl, hasWallet, createWallet } =
    useUserStoreV2();
  const { isCheckingRedeemed, isRedeemed, redeemCode } = useRedeemCode();
  const navigate = useNavigatePreserveQuery();
  const { showToast } = useToastStore();
  const { setIsShowHeader } = useLayoutConfigStore();
  const theme = useTheme();

  const { data: tonBalanceData, isLoading: isBalanceLoading } = useQuery({
    queryKey: ["ton-balance", walletAddresses],
    queryFn: () => {
      return axiosService.getUserBalance();
    },
    enabled: !!walletAddresses,
  });

  // Icon
  const [icon, setIcon] = useState<File | undefined>();
  const cabalIconRef = useRef<HTMLInputElement>(null);

  // Name, Token ticker, Language
  const [name, setName] = useState<string | undefined>();
  const [tokenTicker, setTokenTicker] = useState<string | undefined>();
  const [language, setLanguage] = useState<Language>("English");

  // Curve Steepness
  const [curveSteepness, setCurveSteepness] = useState<Steepness>("Standard");

  // Submition
  const [isValidToSubmit, setIsValidToSubmit] = useState<boolean>(false);

  // Preview
  const [isOpennedPreview, setIsOpennedPreview] = useState(false);

  // Drawer
  const [isOpennedModal, setIsOpennedModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Created cabal id
  const [cabalId, setCabalId] = useState<string | undefined>();

  // Loading State
  const [isLoading, setIsLoading] = useState(false);
  const loadingRef = useRef(false);

  // Balance
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);

  // Code
  const [otp, setOtp] = useState("");

  /* Verify Code Redeemed */
  useEffect(() => {
    if (isRedeemed) {
      setIsShowHeader(true);
    } else {
      setIsShowHeader(false);
    }
  }, [isRedeemed]);

  useEffect(() => {
    if (name && tokenTicker && language && curveSteepness) {
      setIsValidToSubmit(true);
    } else {
      setIsValidToSubmit(false);
    }
  }, [icon, name, tokenTicker, language, curveSteepness]);

  const handleUpload = () => {
    cabalIconRef.current?.click();
  };

  const handleIconSelected = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIcon(file);
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleTokenTickerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTokenTicker(event.target.value);
  };

  const handleSelectLanguage = (event: any) => {
    setLanguage(event.target.value);
  };

  const handleOpenPreview = () => {
    setIsOpennedPreview(true);
  };

  const handleClosePreview = () => {
    setIsOpennedPreview(false);
  };

  const handleOpenSuccessModal = () => {
    setErrorMessage("");
    setIsOpennedModal(true);
  };

  const handleOpenErrorModal = (message: string) => {
    setErrorMessage(message);
    setIsOpennedModal(true);
  };

  const handleCloseModal = () => {
    setIsOpennedModal(false);
  };

  const handleSelectCurveSteepness = (steepness: Steepness) => {
    setCurveSteepness(steepness);
  };

  const handleDone = async () => {
    if (!cabalId) return;
    navigate(ROUTES.cabalId.replace(":id", cabalId));
  };

  // Form submition
  const handleOnSubmit = async () => {
    let res = {
      status: null,
      message: "",
    };

    try {
      loadingRef.current = true;
      setIsLoading(true);

      if (!tonBalanceData?.tonBalance) {
        if (!hasWallet) {
          await createWallet();
        }
        setIsDepositModalOpen(true);
        return setIsLoading(false);
      }

      // Validation
      if (
        !name ||
        !tgUserId ||
        !tokenTicker ||
        !walletAddresses ||
        !walletAddresses.tonAddress ||
        !tgUserName ||
        !curveSteepness ||
        !tokenTicker ||
        !language
      ) {
        throw new Error("Failed to create cabal.");
      }

      // Upload icon
      let iconUrl;
      showToast({ variant: "info", message: `Uploading Cabal Icon...` });
      if (icon) {
        iconUrl = await axiosService.uploadFile(icon);
      } else {
        iconUrl = tgPhotoUrl;
      }

      if (!iconUrl) {
        handleOpenErrorModal("Failed to upload image.");
        return setIsLoading(false);
      }

      // Create cabal
      showToast({ variant: "info", message: `Creating Cabal...` });
      const body = {
        image: iconUrl,
        name: name,
        tgUserId: tgUserId,
        tgUserName: tgUserName,
        reserveRate: SteepnessRR[curveSteepness],
        description: "Cabal",
        symbol: tokenTicker || "",
        decimals: "9",
        language: LanguageShortForm[language],
      };

      const response = await axiosService.insertJettonV2(body);

      const { tgGroupId } = response.res;

      // Check response payload
      if (!tgGroupId) {
        res.message = response.res.message;
        res.status = response.res.status;
        throw new Error("Failed to get tgGroupId");
      }

      // Throw error if status code is not 2xx
      if (response.status > 299) {
        res.message = response.res.message;
        res.status = response.res.status;
        throw new Error(res.message);
      }

      setCabalId(tgGroupId);
      showToast({
        variant: "success",
        message: `Success to create Cabal, Telegram Group ID: ${tgGroupId}`,
        duration: 5000,
      });
      handleOpenSuccessModal();
    } catch (error) {
      if (res.status! >= 400) {
        showToast({ variant: "error", message: res.message, duration: 5000 });
      } else {
        showToast({ variant: "info", message: res.message, duration: 5000 });
      }
      console.error("Failed to create cabal:", error);
      setCabalId(undefined);
      handleOpenErrorModal("Failed to create cabal.");
    } finally {
      setIsLoading(false);
      loadingRef.current = false;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // console.debug("event: ", event.key);
    if (event.key === "Enter") {
      event.preventDefault();
      event.currentTarget.blur();
      // Force any active element to lose focus
      const activeElement = document.activeElement as HTMLElement;
      activeElement?.blur();
    }
  };

  const navigateToExplorer = () => {
    navigate(ROUTES.explorer);
  };

  if (isCheckingRedeemed) {
    return <LoadingView />;
  }

  return isRedeemed ? (
    <>
      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "20px",
            gap: "16px",
          }}>
          <UploadImageContainer>
            <CabalIcon src={icon ? URL.createObjectURL(icon) : tgPhotoUrl}>
              <ImageIcon />
            </CabalIcon>
            <UploadButtonWrapper>
              <RetroButton variant="white" onClick={handleUpload}>
                <Typography variant="title-h3">{icon ? "CHANGE" : "UPLOAD"}</Typography>
              </RetroButton>
              <input
                ref={cabalIconRef}
                type="file"
                onChange={handleIconSelected}
                accept="image/png, image/jpeg"
                hidden
              />
            </UploadButtonWrapper>
          </UploadImageContainer>
          <StyledLabel>
            <Typography variant="body-md" color="text.secondary">
              Alpha group name
            </Typography>
            <StyledInput
              id="name"
              placeholder="Full name"
              onChange={handleNameChange}
              onKeyDown={handleKeyDown}
            />
          </StyledLabel>
          <StyledLabel>
            <Typography variant="body-md" color="text.secondary">
              Group token symbol
            </Typography>
            <StyledInput
              id="name"
              placeholder="e.g. ANSEM"
              onChange={handleTokenTickerChange}
              onKeyDown={handleKeyDown}
            />
          </StyledLabel>
          <StyledLabel>
            <Typography variant="body-md" color="text.secondary">
              Language
            </Typography>
            <StyledSelect id="name" defaultValue="English" onChange={handleSelectLanguage} required>
              <StyledOption value="English">English</StyledOption>
              <StyledOption value="Chinese">中文</StyledOption>
              <StyledOption value="Korean">한국어</StyledOption>
              <StyledOption value="Russian">русский</StyledOption>
            </StyledSelect>
          </StyledLabel>
          <CurveSteepnessHeaderWrapper>
            <Typography variant="body-md" color="text.secondary">
              Price Curves
            </Typography>
            <ViewButtonWrapper>
              <RetroButton variant="white" onClick={handleOpenPreview}>
                <Typography variant="button-md">PREVIEW</Typography>
              </RetroButton>
            </ViewButtonWrapper>
          </CurveSteepnessHeaderWrapper>
          <CurveSteepnessOptionsWrapper>
            {(["Casual", "Standard", "Exclusive"] as Steepness[]).map((option) => (
              <CurveSteepnessOptionRadioContainer
                $selected={curveSteepness === option}
                key={option}
                onClick={() => handleSelectCurveSteepness(option as Steepness)}>
                <CurveSteepnessOption>
                  <PreviewChart
                    variant={curveSteepness === option ? "active" : "non-active"}
                    data={initialData[option as Steepness].slice(
                      0,
                      option === "Standard" ? 75 : SteepnessMaxMemberPreview[option],
                    )}
                  />
                  <CurveSteepnessOptionText variant="body-md">{option}</CurveSteepnessOptionText>
                </CurveSteepnessOption>
                <CurveSteepnessOptionRadio $selected={curveSteepness === option} />
              </CurveSteepnessOptionRadioContainer>
            ))}
          </CurveSteepnessOptionsWrapper>
          <SteepnessPreview
            isOpenned={isOpennedPreview}
            close={handleClosePreview}
            selectedSteepness={curveSteepness}
            handleSelectCurveSteepness={handleSelectCurveSteepness}
          />
          {errorMessage === "" ? (
            <CreateCabalSuccess
              isOpen={isOpennedModal}
              close={handleCloseModal}
              done={handleDone}
            />
          ) : (
            <CreateCabalError
              isOpen={isOpennedModal}
              close={handleCloseModal}
              errorMessage={errorMessage}
            />
          )}
          <CreateCabalButtonWrapper
            $valid={isValidToSubmit && !isLoading && !isBalanceLoading}
            onClick={handleOnSubmit}>
            <RetroButton
              variant={isValidToSubmit && !isLoading && !isBalanceLoading ? "primary" : "disabled"}
              disabled={isLoading || isBalanceLoading || isBalanceLoading}>
              <Typography
                variant="title-h3"
                sx={{ paddingY: "12px" }}
                color={
                  isValidToSubmit && !isLoading && !isBalanceLoading
                    ? "text.brand"
                    : "text.secondary"
                }>
                Create Group
              </Typography>
            </RetroButton>
            <Box sx={{ height: "20px", width: "100%" }} />
          </CreateCabalButtonWrapper>
        </Box>
      </RetroCard>
      <DepositModal
        tokenType={CHAIN_NATIVE_TOKEN_MAP["ton"]}
        isOpen={isDepositModalOpen}
        close={() => setIsDepositModalOpen(false)}
        onCopySuccess={() => {
          setIsDepositModalOpen(false);
          showToast({
            variant: "success",
            message: "Copied address to clipboard",
            duration: 3000,
          });
        }}
      />
    </>
  ) : (
    <>
      <Box
        sx={{
          display: "flex",
          width: "fit-content",
          margin: "20px auto 32px",
        }}>
        <RetroCard>
          <Box
            sx={{
              padding: "17px 32.5px 8px",
            }}>
            <HeaderLogo src={CabalTownLogo} alt="cabal-town-logo" onClick={navigateToExplorer} />
          </Box>
        </RetroCard>
      </Box>
      <Box sx={{ width: "fit-content", margin: "auto" }}>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              height: "100%",
              padding: "20px",
              gap: "16px",
              marginBottom: "16px",
            }}>
            <Typography variant="title-h1">
              <Box sx={{ display: "incline" }}>Build YOUR</Box>
              <Box sx={{ display: "incline" }}>Cabal HERE</Box>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
              }}>
              <OTP separator={<span></span>} value={otp} onChange={setOtp} length={6} />
              <Typography variant="body-md">Enter your Secret Code</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "0 20px 20px",
            }}>
            <RetroButton
              disabled={otp.length !== 6}
              variant={otp.length === 6 ? "primary" : "disabled"}
              color={otp.length === 6 ? "primary" : "text.secondary"}
              onClick={() => redeemCode(otp)}>
              <Box
                sx={{
                  padding: "12px",
                }}>
                <Typography variant="title-h3">Confirm</Typography>
              </Box>
            </RetroButton>
          </Box>
        </RetroCard>
      </Box>
      <Box sx={{ width: "fit-content", margin: "16px auto 0" }}>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: "20px",
            }}>
            <Typography variant="title-h1">WANT CODE?</Typography>
            <Typography variant="body-md" color="text.secondary">
              Follow the 2-step quide below!
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
                margin: "24px 0 0",
              }}>
              {[
                {
                  title: "Step 1",
                  button: "Follow on x",
                  navigate: () => window.open("https://x.com/cabaltown"),
                },
                {
                  title: "Step 2",
                  button: "Message us",
                  navigate: () => window.open("https://t.me/cabaltownofficial"),
                },
              ].map((step, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "16px",
                    padding: "12px",
                    width: "100%",
                    border: `1px solid ${theme.palette.border.dark}`,
                    background: theme.palette.surface.silver,
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "16px",
                      padding: "12px 24px",
                      border: `1px solid ${theme.palette.border.dark}`,
                      background: theme.palette.surface["container-bg"],
                    }}>
                    <Typography variant="body-md">{step.title}</Typography>
                  </Box>
                  <Box sx={{ width: "137px" }}>
                    <RetroButton variant="white" onClick={step.navigate}>
                      <Box
                        sx={{
                          padding: "10px 12px",
                        }}>
                        <Typography variant="button-md">{step.button}</Typography>
                      </Box>
                    </RetroButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </RetroCard>
      </Box>
    </>
  );
});
