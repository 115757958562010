import { Cached } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";

const InsufficientPriceHint = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: theme.spacing(1),
        marginX: "auto",
        textAlign: "center",
        width: "fit-content",
        background: theme.palette.system.red,
        padding: theme.spacing(1, 2),
        borderRadius: theme.spacing(1.5),
        border: `1px solid ${theme.palette.border.dark}`,
      }}>
      <Typography variant="body-default" color={theme.palette.text["system-red"]}>
        insufficient balance for trading
      </Typography>
    </Box>
  );
};

const RefetchPreview = ({
  refetch,
  isRefetching,
  isLoading,
  message,
}: {
  refetch: () => {};
  isRefetching: boolean;
  isLoading: boolean;
  message: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: theme.spacing(1),
        marginX: "auto",
        textAlign: "center",
        width: "fit-content",
        background: theme.palette.system.red,
        padding: theme.spacing(1, 2),
        borderRadius: theme.spacing(1.5),
        border: `1px solid ${theme.palette.border.dark}`,
      }}>
      <Typography
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}
        variant="body-default"
        color={theme.palette.text["system-red"]}>
        {message}
        <Cached
          onClick={() => {
            if (!isLoading && !isRefetching) {
              refetch();
            }
          }}
          sx={{
            cursor: isLoading || isRefetching ? "not-allowed" : "pointer",
            marginY: "auto",
            padding: "0",
            width: "14px",
            height: "14px",
            ...((isLoading || isRefetching) && {
              animation: "spin 2s linear infinite",
              "@keyframes spin": {
                "0%": {
                  transform: "rotate(360deg)",
                },
                "100%": {
                  transform: "rotate(0deg)",
                },
              },
            }),
          }}
        />
      </Typography>
    </Box>
  );
};

export { InsufficientPriceHint, RefetchPreview };
