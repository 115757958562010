import { useEffect, useRef, useState } from "react";
import { ColorType, CrosshairMode, MouseEventParams, Time } from "lightweight-charts";
import { BasicChart, IChartConfig } from "./BasicChart";
import { useChart } from "hooks/useChart";
import { ChartTooltipContainer } from "./ChartTooltip";
import { Box } from "@mui/material";
import { format } from "date-fns";
import theme from "../../theme";

const configs: IChartConfig = {
  base: {
    layout: {
      background: {
        type: ColorType.Solid,
        color: theme.palette.surface.silver,
      },
      textColor: theme.palette.text["btn-dark"],
    },
    grid: {
      horzLines: {
        color: theme.palette.text["btn-dark"],
        visible: true,
      },
      vertLines: {
        visible: false,
      },
    },
    rightPriceScale: {
      visible: false,
    },
    leftPriceScale: {
      visible: false,
    },
    localization: {
      timeFormatter: (time: any) => {
        return time.toString();
      },
    },
    timeScale: {
      tickMarkFormatter: (time: any) => {
        return time.toString();
      },
      visible: false,
      fixLeftEdge: true,
      fixRightEdge: true,
      lockVisibleTimeRangeOnResize: true,
    },
    crosshair: {
      vertLine: {
        color: theme.palette.text["btn-dark"],
        width: 2,
        style: 1,
        visible: true,
        labelVisible: true,
      },
      horzLine: {
        visible: false,
      },
      mode: CrosshairMode.Normal,
    },
    height: 0,
  },
  series: [
    {
      id: "jetton-price", // will affect the tooltip
      area: {
        lineColor: theme.palette.brand.primary,
        topColor: theme.palette.brand.primary,
        bottomColor: theme.palette.surface.silver,
        crosshairMarkerVisible: true,
      },
      line: {
        color: theme.palette.brand.primary,
        lineWidth: 2,
        crosshairMarkerRadius: 10,
        crosshairMarkerVisible: true,
        crosshairMarkerBorderColor: theme.palette.text["btn-dark"],
        crosshairMarkerBackgroundColor: theme.palette.surface.light,
        crosshairMarkerBorderWidth: 3,
      },
    },
  ],
};

export const JettonChart = ({ data }: { data: { time: number; value: number | null }[] }) => {
  const {
    chartRef,
    seriesRef,
    setIsMounted,
    isMounted,
    subscribeCrosshairMove,
    unsubscribeCrosshairMove,
  } = useChart();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [point, setPoint] = useState<{
    x: number;
    y: number;
    data: {
      time: number;
      value: number;
    };
  } | null>(null);

  const X_OFFSET = 60;
  const Y_OFFSET = 48;
  const [tooltipXOffset, setTooltipXOffset] = useState(X_OFFSET);
  const [tooltipYOffset, setTooltipYOffset] = useState(Y_OFFSET);

  useEffect(() => {
    if (data.length > 0) {
      chartRef.current?.timeScale().fitContent();
    }
  }, [data, chartRef]);

  useEffect(() => {
    const handleOnCrosshairMove = (params: MouseEventParams<Time>) => {
      if (!params.point) {
        setPoint(null);
        return;
      }

      if (!containerRef.current) {
        return;
      }

      const series = seriesRef.current["jetton-price"]["area"];
      const data = params.seriesData.get(series) as {
        time: number;
        value: number;
      };

      if (!data || !data.value) {
        setPoint(null);
        return;
      }

      const y = series.priceToCoordinate(data.value);

      const containerHeight = containerRef.current.clientHeight;
      const containerWidth = containerRef.current.clientWidth;

      if (params.point.x > containerWidth / 2) {
        setTooltipXOffset(-X_OFFSET);
      } else {
        setTooltipXOffset(X_OFFSET);
      }

      if (y > containerHeight / 2) {
        setTooltipYOffset(-Y_OFFSET);
      } else {
        setTooltipYOffset(Y_OFFSET);
      }

      setPoint({ x: params.point.x, y, data });
    };

    if (isMounted) {
      setPoint(null);
      unsubscribeCrosshairMove(handleOnCrosshairMove);
      subscribeCrosshairMove(handleOnCrosshairMove);
    }
  }, [isMounted, data]);

  return (
    <BasicChart
      data={data}
      configs={configs}
      chartRef={chartRef}
      seriesRef={seriesRef}
      setIsMounted={setIsMounted}
      chartContainerRef={containerRef}
      tooltip={
        point ? (
          <ChartTooltip
            x={point.x}
            y={point.y}
            xOffSet={tooltipXOffset}
            yOffSet={tooltipYOffset}
            data={point.data}
          />
        ) : (
          <></>
        )
      }
    />
  );
};

const ChartTooltip = ({
  x,
  y,
  data,
  xOffSet,
  yOffSet,
}: {
  x: number;
  y: number;
  data: { time: number; value: number };
  xOffSet: number;
  yOffSet: number;
}) => {
  return (
    <ChartTooltipContainer style={{ top: y + yOffSet - 32, left: x + xOffSet }}>
      <Box>
        <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>${data.value}</Box>
        <Box>{format(new Date(data.time), "dd/MM/yyyy")}</Box>
      </Box>
    </ChartTooltipContainer>
  );
};
