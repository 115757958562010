import { Box } from "@mui/material";
import { DefaultLoginBlockerContent, LoginBlockerWrapper } from "components/LoginBlocker";
import { RetroCard } from "components/RetroCard";
import { CreateCabalV2 } from "components/CreateCabal/CreateCabalV2";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { useEffect } from "react";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";

// TODO: Remove it, only for dev
const LOGIN_REDIRECT_URL = process.env.REACT_APP_TG_WIDGET_LOGIN_URL;

const CreatePage = () => {
  const { tgUserId, tgUserName, getTgUserIdV2 } = useUserStoreV2();

  const handleLogin = async () => {
    try {
      if (!tgUserId || !tgUserName) {
        await getTgUserIdV2();
      }
    } catch (error) {
      console.error("Error while logging in", error);
    }
  };

  return (
    <LoginBlockerWrapper
      isLoginedIn={!!tgUserId || !!tgUserName}
      fallback={
        <RetroCard>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: "20px",
            }}>
            <LogoIcon />
            <Box
              sx={{
                textAlign: "center",
                color: "#fff",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "24px",
              }}>
              <DefaultLoginBlockerContent login={handleLogin} />
            </Box>
          </Box>
        </RetroCard>
      }>
      <CreateCabalV2 />
    </LoginBlockerWrapper>
  );
};

export { CreatePage };
