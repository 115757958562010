import { Box, Typography } from "@mui/material";
import { FullViewContainer, OnboardingCard, OnboardingImage } from "./styled";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { useTheme } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { useEffect, useRef, useState } from "react";
import { RetroButton } from "components/RetroButton";

const onBoardingConfigs = [
  {
    title: "JOIN ALPHA GROUPS",
    description:
      "Connect with top-tier alpha groups for exclusive token ideas. Your shortcut to the best trading insights starts here.",
    image: "/onboarding-1.png",
  },
  {
    title: "GET Token Calls",
    description:
      "Unlock real-time token calls and discover high-potential trades instantly. Simplify your token research and act fast.",
    image: "/onboarding-2.png",
  },
  {
    title: "Trade TOGETHER",
    description:
      "Turn alpha into action. Trade seamlessly across chains with Cabal Town's integrated swap feature.",
    image: "/onboarding-3.png",
  },
];

export const OnboardingView = () => {
  const [slide, setSlide] = useState(0);

  const { skipOnboarding, handleOnboard } = useUserStoreV2();
  const theme = useTheme();

  const handleSlideChange = (index: number) => {
    setSlide(index);
  };

  const handleNextSlide = () => {
    setSlide(slide + 1);
  };

  return skipOnboarding ? (
    <></>
  ) : (
    <FullViewContainer>
      <OnboardingCard
        sx={{
          display: "flex",
          position: "relative",
          overflowX: "scroll",
          scrollSnapType: "x mandatory",
          msScrollSnapPointsX: "repeat(100vw)",
          width: "100vw",
        }}>
        {onBoardingConfigs.map((config, index) => {
          return (
            <OnBoardingItem
              config={config}
              index={index}
              handleSlideChange={handleSlideChange}
              slid={slide}
            />
          );
        })}
        <Box
          sx={{
            position: "fixed",
            bottom: "24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              padding: "12px 0 16px",
            }}>
            {[0, 1, 2].map((pageIndex) => (
              <Box
                sx={{
                  border: `1px solid ${theme.palette.border.dark}`,
                  borderRadius: "50%",
                  width: "12px",
                  height: "12px",
                  background:
                    slide === pageIndex
                      ? theme.palette.brand.primary
                      : theme.palette.surface.silver,
                }}
              />
            ))}
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "400px",
              padding: "0 36px",
            }}>
            <RetroButton variant="primary" onClick={slide === 2 ? handleOnboard : handleNextSlide}>
              <Box padding="12px 0">
                {slide === 2 ? (
                  <Typography variant="title-h3">Let’s go</Typography>
                ) : (
                  <Typography variant="title-h3">Next</Typography>
                )}
              </Box>
            </RetroButton>
          </Box>
        </Box>
      </OnboardingCard>
    </FullViewContainer>
  );
};

interface OnBoardingItemProps {
  title: string;
  description: string;
  image: string;
}

const OnBoardingItem = ({
  config,
  index,
  slid,
  handleSlideChange,
}: {
  config: OnBoardingItemProps;
  index: number;
  slid: number;
  handleSlideChange: (slide: number) => void;
}) => {
  const { title, description, image } = config;

  const { ref, inView } = useInView();
  const theme = useTheme();
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inView) {
      handleSlideChange(index);
    }
  }, [inView]);

  useEffect(() => {
    if (slid === index && !inView) {
      scrollRef.current?.scrollIntoView();
    }
  }, [slid]);

  return (
    <Box
      ref={scrollRef}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        height: "100%",
        padding: "20px",
        minWidth: "100%",
        scrollSnapAlign: "start",
      }}>
      <OnboardingImage
        ref={ref}
        src={image}
        sx={{
          width: "240px",
          aspectRatio: "1/1",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          maxWidth: "600px",
        }}>
        <Box>
          <Typography variant="title-h2" color={theme.palette.brand.primary}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body-md" color={theme.palette.text.secondary}>
            {description}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ height: "96px" }} />
    </Box>
  );
};
