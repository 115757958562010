import { styled } from "@mui/material";

export const FullViewContainer = styled("div")(({ theme }) => ({
  display: "flex",
  position: "fixed",
  top: "73px",
  left: 0,
  zIndex: 99999999,
  minWidth: "100vw",
  height: "calc(100vh - 73px)",
  padding: "0px",
  backgroundColor: theme.palette.surface.silver,
}));

export const OnboardingCard = styled("div")(({ theme }) => ({
  background: `url("/onboarding-bg.png") repeat-x`,
  backgroundSize: "fixed",
  backgroundPosition: "bottom",
  backgroundColor: theme.palette.surface.bg,
}));

export const OnboardingImage = styled("img")(({ theme }) => ({
  width: "80%",
  maxWidth: "400px",
  aspectRatio: "1/1",
}));
