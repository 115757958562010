import theme from "theme";
import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { RetroButton } from "components/RetroButton";
import {
  PortfolioDetailListContainer,
  PortfolioDetailListHeader,
  PortfolioDetailListItemColumnContainer,
  PortfolioDetailListItemContainer,
  PortfolioDetailListTitle,
  TapsContainer,
} from "./styled";
import { PricePerformanceBrick } from "components/PercentageBrick";
import CoinAvatar, { NATIVE_TOKEN_MAP } from "components/CoinAvatar";
import { useInfiniteQuery } from "@tanstack/react-query";
import { UserPortfolioAssets, UserPortfolioEarning, UserPortfolioJoinedGroup } from "types";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { LoadingLottie } from "components/LoadingScreen";
import { useInViewInfiniteQuery } from "hooks/useInViewInfiniteQuery";
import { ScrollableBox } from "components/ScrollableBox";
import { LoadMoreRow } from "components/LoadMoreRow";
import { useNavigate } from "react-router-dom";
import { SmallNumberView } from "components/SmallValueView/SmallValueView";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { Cached } from "@mui/icons-material";
import useToastStore from "store/toast-store/useToastStore";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import axiosService from "services/axios";

const NATIVE_TOKEN_LIST = ["TON", "SOL", "ETH"];
const WRAPPED_TOKEN_LIST = ["WSOL", "WETH"];

export const PortfolioDetail = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <TapsContainer>
        {["Assets", "Earning", "Groups"].map((tab, index) => {
          return (
            <RetroButton disabled={selectedTab === index} onClick={() => setSelectedTab(index)}>
              <Box
                sx={{
                  padding: "12px",
                }}>
                <Typography
                  variant="body-default"
                  color={selectedTab === index ? "text.primary" : "text.brand"}>
                  {tab}
                </Typography>
              </Box>
            </RetroButton>
          );
        })}
      </TapsContainer>
      <PortfolioDetailListContainer>
        {selectedTab === 0 && <AssetsView />}
        {selectedTab === 1 && <EarningView />}
        {selectedTab === 2 && <CabalsView />}
      </PortfolioDetailListContainer>
    </>
  );
};

const AssetsView = () => {
  const queryResult = useInfiniteQuery({
    queryKey: ["portfolio-assets", { page: 1 }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getUserPortfolioAssets({ limit: 10, page: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { data, isLoading, refetch, isRefetching } = queryResult;
  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  const refetchAssets = useCallback(
    async (symbol: string) => {
      const MAX_ATTEMPTS = 10;
      let attempts = 0;
      let isSymbolFound = true;

      while (isSymbolFound && attempts < MAX_ATTEMPTS) {
        const result = await refetch();
        attempts++;

        const asset = result.data?.pages
          ?.flatMap((page) => page.results)
          .find((item) => item.symbol === symbol);

        isSymbolFound = !!asset;

        if (isSymbolFound) {
          // Wait a bit before next attempt
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      }
    },
    [refetch],
  );

  return (
    <>
      <PortfolioDetailListHeader>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            TOKEN
          </Typography>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Box
            sx={{
              marginLeft: "-8px",
            }}>
            <Typography variant="pre-title-2" color="text.secondary">
              USD VALUE
            </Typography>
          </Box>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginRight: "20px",
            }}>
            <Cached
              onClick={() => refetch()}
              sx={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
                ...((isLoading || isRefetching) && {
                  animation: "spin 2s linear infinite",
                  "@keyframes spin": {
                    "0%": {
                      transform: "rotate(360deg)",
                    },
                    "100%": {
                      transform: "rotate(0deg)",
                    },
                  },
                }),
              }}
            />
          </Box>
        </PortfolioDetailListTitle>
      </PortfolioDetailListHeader>
      <TabLoadingSuspense isLoading={isLoading}>
        {data && data.pages.length > 0 && data.pages[0].totalResults > 0 ? (
          <ScrollableBox>
            {data.pages
              ?.flatMap((page, index) => page.results)
              .map((groupdata, index) => (
                <AssetsListItem key={index} data={groupdata} refetch={refetchAssets} />
              ))}
            {!hasAllFetched && <LoadMoreRow ref={ref} />}
          </ScrollableBox>
        ) : (
          <EmptyView portfolioType="Assets" />
        )}
      </TabLoadingSuspense>
    </>
  );
};

const EarningView = () => {
  const { walletAddresses } = useUserStoreV2();

  const queryResult = useInfiniteQuery({
    queryKey: ["portfolio-earnings", { page: 1 }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getUserPortfolioEarning({ limit: 10, page: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { data, isLoading, refetch, isRefetching } = queryResult;
  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  return (
    <>
      <PortfolioDetailListHeader>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            TOKEN
          </Typography>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Box
            sx={{
              marginLeft: "-8px",
            }}>
            <Typography variant="pre-title-2" color="text.secondary">
              USD VALUE
            </Typography>
          </Box>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginRight: "20px",
            }}>
            <Cached
              onClick={() => refetch()}
              sx={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
                ...((isLoading || isRefetching) && {
                  animation: "spin 2s linear infinite",
                  "@keyframes spin": {
                    "0%": {
                      transform: "rotate(360deg)",
                    },
                    "100%": {
                      transform: "rotate(0deg)",
                    },
                  },
                }),
              }}
            />
          </Box>
        </PortfolioDetailListTitle>
      </PortfolioDetailListHeader>
      <TabLoadingSuspense isLoading={isLoading}>
        {data && data.pages.length > 0 && data.pages[0].totalResults > 0 ? (
          <ScrollableBox>
            {data.pages
              ?.flatMap((page, index) => page.results)
              .map((earningData, index) =>
                earningData.chain === "solana" ? (
                  <EarningListItem
                    key={index}
                    data={earningData}
                    refetch={refetch}
                    walletAddress={walletAddresses ? walletAddresses["solAddress"] : ""}
                  />
                ) : (
                  <EarningListItem key={index} data={earningData} refetch={refetch} />
                ),
              )}
            {!hasAllFetched && <LoadMoreRow ref={ref} />}
          </ScrollableBox>
        ) : (
          <EmptyView portfolioType="Earning" />
        )}
      </TabLoadingSuspense>
      <Typography variant="body-md" color="text.secondary">
        NOTE: You are eligible to claim once the claimable amount exceed $10
      </Typography>
    </>
  );
};

const CabalsView = () => {
  const queryResult = useInfiniteQuery({
    queryKey: ["portfolio-groups", { page: 1 }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getUserPortfolioGroups({ limit: 10, page: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { data, isLoading, refetch, isRefetching } = queryResult;
  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  return (
    <>
      <PortfolioDetailListHeader>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            CABAL
          </Typography>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Box
            sx={{
              marginLeft: "-8px",
            }}>
            <Typography variant="pre-title-2" color="text.secondary">
              USD VALUE
            </Typography>
          </Box>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginRight: "20px",
            }}>
            <Cached
              onClick={() => refetch()}
              sx={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
                ...((isLoading || isRefetching) && {
                  animation: "spin 2s linear infinite",
                  "@keyframes spin": {
                    "0%": {
                      transform: "rotate(360deg)",
                    },
                    "100%": {
                      transform: "rotate(0deg)",
                    },
                  },
                }),
              }}
            />
          </Box>
        </PortfolioDetailListTitle>
      </PortfolioDetailListHeader>
      <TabLoadingSuspense isLoading={isLoading}>
        {data && data.pages.length > 0 && data.pages[0].totalResults > 0 ? (
          <ScrollableBox>
            {data.pages
              ?.flatMap((page, index) => page.results)
              .map((groupdata, index) => (
                <CabalListItem key={index} data={groupdata} />
              ))}
            {!hasAllFetched && <LoadMoreRow ref={ref} />}
          </ScrollableBox>
        ) : (
          <EmptyView portfolioType="Cabals" />
        )}
      </TabLoadingSuspense>
    </>
  );
};

const AssetsListItem = ({
  data,
  refetch,
}: {
  data: UserPortfolioAssets;
  refetch: (symbol: string) => Promise<any>;
}) => {
  const navigate = useNavigate();
  const { showToast } = useToastStore();
  const { walletAddresses } = useUserStoreV2();

  const [isLoading, setIsLoading] = useState(false);

  const symbol = data.symbol.toUpperCase();
  const isNativeToken = NATIVE_TOKEN_LIST.includes(symbol);
  const isWrappedToken = WRAPPED_TOKEN_LIST.includes(symbol);

  const buttonText = isWrappedToken ? "UNWRAP" : "TRADE";

  const navigateToTrade = async () => {
    if (isWrappedToken) {
      try {
        setIsLoading(true);
        const res =
          symbol === "WETH"
            ? await axiosService.unwrapEth({
                amount: data.balance,
                senderAddress: walletAddresses?.ethAddress!!,
                tokenAddress: data.tokenAddress,
                chain: data.chain,
              })
            : await axiosService.unwrapSol({
                senderAddress: walletAddresses?.solAddress!!,
                tokenAddress: data.tokenAddress,
              });

        if (res.status < 300) {
          showToast({
            variant: "success",
            message: res.message,
            duration: 5000,
          });
        } else {
          showToast({
            variant: "error",
            message: res.message,
            duration: 5000,
          });
        }
      } catch (error) {
        console.error("Failed to unwrap token", error);
      } finally {
        await refetch(data.symbol);
      }

      return;
    }

    navigate({
      pathname: `/token/${data.tokenAddress}`,
      search: `chain=${data.chain}${data.tgGroupId ? `&groupId=${data.tgGroupId}` : ""}`,
    });
  };

  return (
    <PortfolioDetailListItemContainer>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 0%",
          justifyContent: "start",
          alignItems: "center",
          gap: "2px",
          overflow: "hidden",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "8px",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}>
          <CoinAvatar
            style={{
              width: "28px",
              height: "28px",
            }}
            badgeStyle={{
              width: "14px",
              height: "14px",
            }}
            iconUri={data.imageUrl ? data.imageUrl : NATIVE_TOKEN_MAP[symbol]}
            chain={data.chain}
            size="sm"
          />
          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "100%",
            }}
            variant="subtitle">
            {data.symbol}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          gap: "12px",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flex: "1 1 0%",
          }}>
          <Typography variant="body-default" color="text.primary">
            <SmallNumberView value={+data.priceUsd} isUSD />
          </Typography>
          {isNativeToken ? null : (
            <PricePerformanceBrick
              performance={data.performance ? +data.performance.toFixed(4) : 0}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          textAlign: "center",
          gap: "12px",
        }}>
        <Box
          sx={{
            width: "78px",
            height: "46px",
          }}>
          <RetroButton
            variant={isNativeToken ? "disabled" : "primary"}
            onClick={navigateToTrade}
            disabled={isNativeToken || isLoading}>
            <Box
              sx={{
                padding: "12px",
              }}>
              <Typography
                variant="button-md"
                color={isNativeToken || isLoading ? "text.secondary" : "text.brand"}>
                {buttonText}
              </Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
    </PortfolioDetailListItemContainer>
  );
};

const EarningListItem = ({
  data,
  refetch,
  walletAddress,
}: {
  data: UserPortfolioEarning;
  refetch: () => Promise<any>;
  walletAddress?: string;
}) => {
  const CLAIM_AVAILABLE_USD_REQUIREMENT = 10;
  const CREATE_ACCOUNT_USD_THRESHOLD = 0.1;

  const { showToast } = useToastStore();
  const [isClaiming, setIsClaiming] = useState(false);
  const [isCheckingAccount, setIsCheckingAccount] = useState(false);
  const claimingRef = useRef(false);
  const checkingRef = useRef(false);

  useEffect(() => {
    const checkAccountCreated = async () => {
      if (checkingRef.current || isCheckingAccount) return;

      try {
        checkingRef.current = true;
        setIsCheckingAccount(true);

        const { status } = await axiosService.createSolTokenAccount({
          toAddress: walletAddress!,
          feeToken: data.feeToken,
        });

        if (status >= 400) {
          throw new Error("Failed to check account");
        }
      } catch (error) {
        console.error("Failed to check account", error);
      } finally {
        setIsCheckingAccount(false);
        checkingRef.current = false;
      }
    };

    if (
      walletAddress &&
      +data.priceUsd > CLAIM_AVAILABLE_USD_REQUIREMENT - CREATE_ACCOUNT_USD_THRESHOLD
    ) {
      checkAccountCreated();
    }
  }, [data.feeToken, data.priceUsd, isCheckingAccount, walletAddress]);

  const handleClaim = async () => {
    if (claimingRef.current || isClaiming) return;

    try {
      claimingRef.current = true;
      setIsClaiming(true);
      const payload = {
        chain: data.chain,
        feeToken: data.feeToken,
        totalFeeAmounts: data.totalFeeAmounts,
      };
      const res = await axiosService.claimReward(payload);
      if (res.status < 300) {
        showToast({
          variant: "success",
          message: res.message,
          duration: 5000,
        });
      } else {
        showToast({
          variant: "error",
          message: res.message ?? "Failed to claim reward",
          duration: 5000,
        });
      }
    } catch (error) {
      showToast({ variant: "error", message: "Failed to claim reward", duration: 5000 });
    } finally {
      await refetch();
      claimingRef.current = false;
      setIsClaiming(false);
    }
  };

  return (
    <PortfolioDetailListItemContainer>
      <PortfolioDetailListItemColumnContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "8px",
          }}>
          <CoinAvatar
            style={{
              width: "28px",
              height: "28px",
            }}
            badgeStyle={{
              width: "14px",
              height: "14px",
            }}
            iconUri={NATIVE_TOKEN_MAP[data.symbol.toUpperCase()]}
            chain={data.chain}
            size="sm"
          />
          <Typography
            sx={{
              maxWidth: "86px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            variant="subtitle">
            {data.symbol}
          </Typography>
        </Box>
      </PortfolioDetailListItemColumnContainer>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          gap: "12px",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flex: "1 1 0%",
          }}>
          <Typography variant="body-default" color="text.primary">
            <SmallNumberView value={+data.priceUsd} isUSD />
          </Typography>
        </Box>
        <Box
          sx={{
            width: "78px",
            height: "46px",
          }}>
          <RetroButton
            disabled={
              isClaiming || isCheckingAccount || +data.priceUsd < CLAIM_AVAILABLE_USD_REQUIREMENT
            }
            variant={
              isClaiming || isCheckingAccount || +data.priceUsd < CLAIM_AVAILABLE_USD_REQUIREMENT
                ? "disabled"
                : "primary"
            }
            onClick={handleClaim}>
            <Box
              sx={{
                padding: "12px",
              }}>
              <Typography
                variant="button-md"
                color={
                  isClaiming ||
                  isCheckingAccount ||
                  +data.priceUsd < CLAIM_AVAILABLE_USD_REQUIREMENT
                    ? "text.secondary"
                    : "text.brand"
                }>
                {isCheckingAccount ? "LOADING" : isClaiming ? "CLAIMING" : "CLAIM"}
              </Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
    </PortfolioDetailListItemContainer>
  );
};

const CabalListItem = ({ data }: { data: UserPortfolioJoinedGroup }) => {
  const navigate = useNavigatePreserveQuery();
  const navigateToGroup = () => {
    navigate(`/cabal/${data.tgGroupId}`);
  };

  return (
    <PortfolioDetailListItemContainer>
      <PortfolioDetailListItemColumnContainer>
        <CoinAvatar
          style={{
            width: "36px",
            height: "36px",
          }}
          iconUri={data.imageUri}
          size="sm"
        />
        <Typography
          sx={{
            maxWidth: "86px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            marginLeft: "8px",
          }}
          variant="subtitle">
          {data.symbol}
        </Typography>
      </PortfolioDetailListItemColumnContainer>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          gap: "12px",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flex: "1 1 0%",
          }}>
          <Typography variant="body-default" color="text.primary">
            {data && data.priceUsd ? <SmallNumberView value={data.priceUsd} isUSD /> : "$--"}
          </Typography>
          <PricePerformanceBrick
            performance={data.performance ? +data.performance.toFixed(2) : 0}
          />
        </Box>
        <Box
          sx={{
            width: "78px",
            height: "46px",
          }}>
          <RetroButton variant="white" onClick={navigateToGroup}>
            <Box
              sx={{
                padding: "12px",
              }}>
              <Typography variant="button-md" color="text.btn-dark">
                VIEW
              </Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
      {/* <LoadingView isLoading={isLoading}/> */}
    </PortfolioDetailListItemContainer>
  );
};

const TabLoadingSuspense = ({ isLoading, children }: { isLoading: boolean; children: any }) => {
  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "96px",
        gap: "40px",
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "56px",
          height: "56px",
        }}>
        <LoadingLottie />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "56px",
          height: "56px",
        }}>
        <Typography variant="body-lg" color="text.primary">
          Loading...
        </Typography>
      </Box>
    </Box>
  ) : (
    children
  );
};

const EmptyView = ({ portfolioType }: { portfolioType: "Assets" | "Earning" | "Cabals" }) => {
  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "45px",
        backgroundColor: theme.palette.surface.silver,
        borderRadius: "16px",
        gap: "24px",
        border: `1px solid ${theme.palette.border.dark}`,
      }}>
      <LogoIcon />
      <Box
        sx={{
          textAlign: "center",
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}>
        <Typography variant="title-h2">{portfolioType} Empty</Typography>
        <Typography variant="body-lg">
          You do not have any {portfolioType.toLowerCase()} yet
        </Typography>
      </Box>
    </Box>
  );
};
