import { Box } from "@mui/material";
import { abbreviateNumber } from "utils";
import { ArrowBackIosNew } from "@mui/icons-material";

type ArrowSizeVariants = "body-default" | "body-md" | "title-h2" | "display-md" | "pre-title";

const ArrowSizeVariantsMap: Record<ArrowSizeVariants, number> = {
  "body-default": 10,
  "body-md": 12,
  "title-h2": 16,
  "display-md": 24,
  "pre-title": 10,
};

export const SmallNumberView = ({
  value,
  isUSD = false,
  variants = "body-default",
  min = 0.01,
}: {
  value: number;
  isUSD?: boolean;
  variants?: ArrowSizeVariants;
  min?: number;
}) => {
  const valueUnit = isUSD ? "$" : "";

  if (value === 0) {
    return (
      <Box sx={{ display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
        {`${valueUnit}0.00`}
      </Box>
    );
  }

  return value < min ? (
    <Box sx={{ display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
      <ArrowBackIosNew
        sx={{ height: ArrowSizeVariantsMap[variants], width: ArrowSizeVariantsMap[variants] }}
      />
      {`${valueUnit}${min}`}
    </Box>
  ) : (
    <>
      {valueUnit}
      {value ? abbreviateNumber(Number(value.toFixed(2))) : "--"}
    </>
  );
};
