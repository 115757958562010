import axios from "axios";

export type UserData = {
  userData: unknown;
};

let accessToken: string | null = localStorage.getItem("accessToken");
let refreshToken: string | null = localStorage.getItem("refreshToken");
let refreshSubscribers: ((token: string) => void)[] = [];

const setTokens = (access: string | null, refresh: string | null) => {
  accessToken = access;
  refreshToken = refresh;
  if (!access || !refresh) {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
  } else {
    localStorage.setItem("refreshToken", refresh);
    localStorage.setItem("accessToken", access);
  }
};

const getAccessToken = () => accessToken || localStorage.getItem("accessToken");

const refreshAccessToken = async () => {
  try {
    if (!refreshToken) {
      refreshToken = localStorage.getItem("refreshToken");
    }
    const response = await axios.post(process.env.REACT_APP_API_URL + "/v1/auth/refresh-tokens", {
      refreshToken,
    });

    const { tokens } = response.data;
    if (tokens) {
      setTokens(tokens.access.token, tokens.refresh.token);
    }

    return tokens.access.token;
  } catch (error) {
    setTokens(null, null);
    throw error;
  }
};

const tgAuth = async (userData: UserData) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/v1/auth/register",
      userData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const { tokens, message, status } = response.data;

    if (tokens) {
      setTokens(tokens.access.token, tokens.refresh.token);
    } else if (status === 404) {
      throw new Error(message);
    } else {
      throw new Error("Failed to authenticate");
    }

    return response.data;
  } catch (error) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/v1/auth/login",
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const { tokens, message, status } = response.data;

      if (tokens) {
        setTokens(tokens.access.token, tokens.refresh.token);
      } else if (status === 404) {
        throw new Error(message);
      } else {
        throw new Error("Failed to authenticate");
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

const onRefreshed = (token: string) => {
  refreshSubscribers.forEach((callback) => callback(token));
};

const addRefreshSubscriber = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

const removeTokens = () => {
  setTokens(null, null);
};

export {
  setTokens,
  getAccessToken,
  refreshAccessToken,
  tgAuth,
  onRefreshed,
  addRefreshSubscriber,
  removeTokens,
};
