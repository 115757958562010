import { useRecoilState } from "recoil";
import { toastStateAtom } from ".";
import { useRef } from "react";

const useToastStore = () => {
  const [toastState, setToastState] = useRecoilState(toastStateAtom);
  const currentToastIdRef = useRef<NodeJS.Timeout | null>(null);

  const showToast = (params: {
    variant: "success" | "error" | "info";
    message?: string;
    duration?: number;
  }) => {
    if (currentToastIdRef.current) {
      clearTimeout(currentToastIdRef.current);
    }
    setToastState(params);

    if (params.duration) {
      currentToastIdRef.current = setTimeout(() => {
        resetToast();
      }, params.duration);
    }
  };

  const resetToast = () => {
    setToastState((prev) => ({ ...prev, message: undefined }));
  };

  return { toastState, showToast, resetToast };
};

export default useToastStore;
