import { Typography } from "@mui/material";

import { Box } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import { LoadingLottie } from "components/LoadingScreen";

const LoadingView = () => {
  return (
    <RetroCard>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "20px",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80px",
            height: "80px",
          }}>
          <LoadingLottie />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}>
          <Typography variant="title-h2">Loading...</Typography>
        </Box>
      </Box>
    </RetroCard>
  );
};

export { LoadingView };
