import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loader-cabal_town.json";

import { ReactNode } from "react";
import { LoadingViewContainer, LoadingViewType } from "./styled";

export interface LoadingScreenProps {
  isLoading: boolean;
  children: ReactNode;
}

export const LoadingScreen = ({ isLoading, children }: LoadingScreenProps) => {
  return (
    <ConditionalSuspense
      condition={isLoading}
      fallback={<LoadingView variant="full-screen" isLoading={true} />}>
      {children}
    </ConditionalSuspense>
  );
};

interface ConditionalSuspenseProps {
  condition: boolean;
  children: ReactNode;
  fallback: ReactNode;
}

const ConditionalSuspense = ({ condition, children, fallback }: ConditionalSuspenseProps) => {
  return condition ? <>{children}</> : <>{fallback}</>;
};

export const LoadingView = ({
  variant = "overlay",
  isLoading,
}: {
  variant?: LoadingViewType;
  isLoading: boolean;
}) => {
  return isLoading ? (
    <LoadingViewContainer $variant={variant}>
      <LoadingLottie />
    </LoadingViewContainer>
  ) : (
    <></>
  );
};

export const LoadingLottie = () => {
  return <Lottie animationData={loadingAnimation} />;
};
