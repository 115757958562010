import { Close, Tune } from "@mui/icons-material";
import { Box, DrawerProps, MenuItem, Typography, useTheme } from "@mui/material";
import { CircleButton } from "components/CircleButton";
import { RetroButton } from "components/RetroButton";
import { SmallNumberView } from "components/SmallValueView/SmallValueView";
import { buttonConfig, DarkDrawer, NumpadKey } from "components/TradeDrawer";
import { StyledSelect } from "./styled";
import { Chain } from "types";
import { useRef, useState } from "react";
import { ReactComponent as XSquare } from "assets/icons/xsquare.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import RetroModal from "components/RetroModal";
import { CHAIN_BADGE_MAP } from "components/CoinAvatar";
import useUserStoreV2, { DEFAULT_CACHED_SLIPPAGE } from "store/user-store-v2/useUserStoreV2";
import { styled } from "@mui/material";
import { toFixedIfNecessary } from "utils";
import { StyledInputV2 } from "components/form/input/styled";
import useTradeStore from "store/trade-store.ts/useTradeStore";
import { EVM_CHAINS, useTradeContext } from "contexts/TradeContext";
import { useApproval, usePriceImpact, useTradeDrawer } from "hooks/useTradeDrawer";
import { RefetchPreview } from "./priceHint";
import { MAX_SLIPPAGE, MIN_ENTRY_USD, MIN_SLIPPAGE } from "consts";

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  useGrouping: false,
});

export const CHAIN_ABBREVIATION_MAP: Record<string, string> = {
  solana: "SOL",
  ethereum: "ETH",
  base: "BASE",
};

export const NATIVE_TOKEN_ADDRESS_MAP: Record<"solana" | "ethereum" | "base", string> = {
  solana: "So11111111111111111111111111111111111111112",
  base: "0x4200000000000000000000000000000000000006",
  ethereum: "0xEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE",
};

interface CrossChainTradeDrawerProps {
  chain: Chain;
  groupId: number;
  isFromTrendingCall?: boolean;
  onProcessing?: () => void;
  onProcessed?: () => void;
  onConfirm?: (message?: string) => void;
  onFail?: (message?: string) => void;
  onClose: () => void;
}

export const CrossChainTradeDrawer = (props: DrawerProps & CrossChainTradeDrawerProps) => {
  const {
    mode,
    nativeTokenConfig,
    targetTokenConfig,
    selectedNativeChain,
    selectedTargetChain,
    targetTokenPrice,
    targetBalanceAmount,
    handleSetMode,
    handleSetSelectedNativeChain,
  } = useTradeContext();

  const {
    inputUsd,
    targetAmount,
    nativeAmount,
    shouldConfirmDisabled,
    isSellAll,
    isOpenConfirmDrawer,
    onConfirm,
    onClose,
    onNumpadPress,
    onPercentPress,
    handleOpenConfirmDrawer,
    priceHint,
  } = useTradeDrawer({
    tgGroupId: props.groupId,
    isFromTrendingCall: props.isFromTrendingCall,
    onConfirm: props.onConfirm,
    onProcessing: props.onProcessing,
    onProcessed: props.onProcessed,
    onClose: () => {
      handleSetMode("BUY");
      props.onClose();
    },
    onFail: props.onFail,
  });

  const {
    priceImpact,
    noValidRouteMessage,
    isLoadingPriceImpact,
    isRefetchingPriceImpact,
    refetchPriceImpact,
  } = usePriceImpact({
    isSellAll,
    targetAmount,
    nativeAmount,
  });

  const { isApproving, isApproveSucess, isGetCurrentAllowanceLoading, handleApproveAllowance } =
    useApproval({ targetAmount });

  const { getUserBalance } = useTradeStore();

  // Slippage
  const [isOpenSlippageDrawer, setIsOpenSlippageDrawer] = useState(false);
  const [isSlippageValid, setIsSlippageValid] = useState(false);
  const slippageRef = useRef<HTMLInputElement>(null);

  // Reminder Drawer
  const [shouldShowAmountTooSmall, setShouldShowAmountTooSmall] = useState(false);

  // Remindar Modal
  const [modalConfig, setModalConfig] = useState<{
    variant: "success" | "error";
    title: string;
    message: string;
  } | null>(null);

  const theme = useTheme();

  // User
  const { preferences = { slippage: DEFAULT_CACHED_SLIPPAGE }, setSlippage } = useUserStoreV2();
  const { slippage } = preferences;

  // Slippage
  const handleOpenSlippageDrawer = () => {
    setIsOpenSlippageDrawer(true);
  };

  // Start Trading
  const handleConfirm = (slippage?: number) => {
    // Checks
    if (!slippage) {
      console.error("Slippage is not set");
      return;
    }

    if (mode === "BUY" && +inputUsd < MIN_ENTRY_USD) {
      setShouldShowAmountTooSmall(true);
      return;
    }

    handleOpenConfirmDrawer(true);
  };

  // Slippage
  const handleSlippageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = parseFloat(event.target.value);
      setIsSlippageValid(() => !isNaN(value) && value >= MIN_SLIPPAGE && value <= MAX_SLIPPAGE);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmSlippage = () => {
    try {
      if (!slippageRef.current) {
        return;
      }

      const value = parseFloat(slippageRef.current.value);
      setSlippage(value);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <DarkDrawer anchor="bottom" sx={{ zIndex: 100 }} open={props.open}>
        {/* Tool Bar */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {/* Slippage Button */}
          <Box>
            <CircleButton variant="white" size="lg" onClick={handleOpenSlippageDrawer}>
              <Tune />
            </CircleButton>
          </Box>
          {/* Buy / Sell */}
          <Box
            sx={{
              background: theme.palette.surface.container,
              borderRadius: theme.spacing(2),
              border: `1px solid ${theme.palette.border.dark}`,
              padding: theme.spacing(1),
              display: "flex",
              gap: theme.spacing(1),
            }}>
            <RetroButton onClick={() => handleSetMode("SELL")} disabled={mode === "SELL"}>
              <Box sx={{ padding: theme.spacing(1.5, 3) }}>
                <Typography variant="body-md">Sell</Typography>
              </Box>
            </RetroButton>
            <RetroButton onClick={() => handleSetMode("BUY")} disabled={mode === "BUY"}>
              <Box sx={{ padding: theme.spacing(1.5, 3) }}>
                <Typography variant="body-md">Buy</Typography>
              </Box>
            </RetroButton>
          </Box>
          {/* Close Button */}
          <Box>
            <CircleButton variant="white" size="lg" onClick={onClose}>
              <Close />
            </CircleButton>
          </Box>
        </Box>
        {/* Trade detail */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "32px",
            gap: "8px",
          }}>
          {/* Source Token Select (balance USD) */}
          <Box sx={{ flex: 1, display: "flex", justifyContent: "end", alignItems: "center" }}>
            <Box sx={{ width: "158px" }}>
              <StyledSelect
                defaultValue={
                  mode === "BUY"
                    ? selectedNativeChain
                    : targetTokenConfig[selectedTargetChain].symbol
                }
                noPointer={mode === "SELL"}
                value={
                  mode === "BUY"
                    ? selectedNativeChain
                    : targetTokenConfig[selectedTargetChain].symbol
                }
                sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
                MenuProps={{
                  slotProps: {
                    paper: {
                      sx: {
                        borderRadius: theme.spacing(1.5),
                        backgroundColor: theme.palette.surface.silver,
                      },
                    },
                  },
                }}
                onChange={(event) => handleSetSelectedNativeChain(event.target.value as Chain)}
                IconComponent={mode === "SELL" ? () => null : undefined}>
                {/* Default Option */}
                {mode === "BUY" ? (
                  Object.keys(CHAIN_ABBREVIATION_MAP).map((chain) => (
                    <MenuItem
                      selected={selectedNativeChain === chain}
                      value={chain}
                      sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                      <img
                        src={CHAIN_BADGE_MAP[chain as Chain]}
                        alt={CHAIN_ABBREVIATION_MAP[chain as Chain]}
                        width={16}
                        height={16}
                      />
                      <Typography
                        sx={{
                          width: "100%",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          gap: theme.spacing(1),
                        }}
                        variant="body-default"
                        color={theme.palette.text.secondary}>
                        <SmallNumberView
                          value={getUserBalance(chain, "amount")!}
                          variants="body-default"
                        />{" "}
                        {EVM_CHAINS.includes(chain as Chain)
                          ? CHAIN_ABBREVIATION_MAP["ethereum"]
                          : CHAIN_ABBREVIATION_MAP[chain as Chain]}
                      </Typography>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem
                    selected={true}
                    value={targetTokenConfig[selectedTargetChain].symbol}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}>
                    <img
                      src={targetTokenConfig[selectedTargetChain].imageUrl}
                      alt={targetTokenConfig[selectedTargetChain].symbol}
                      width={16}
                      height={16}
                      style={{ borderRadius: "50%" }}
                    />
                    <Typography
                      sx={{ width: "100%", textAlign: "center" }}
                      variant="body-default"
                      color={theme.palette.text.secondary}>
                      <SmallNumberView value={targetBalanceAmount!} variants="body-default" />{" "}
                      {targetTokenConfig[selectedTargetChain].symbol}
                    </Typography>
                  </MenuItem>
                )}
              </StyledSelect>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ArrowRight width={24} />
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "start", alignItems: "center" }}>
            <Box sx={{ width: "158px" }}>
              <StyledSelect
                defaultValue={
                  mode === "SELL"
                    ? selectedNativeChain
                    : targetTokenConfig[selectedTargetChain].symbol
                }
                noPointer={mode === "BUY"}
                value={
                  mode === "SELL"
                    ? selectedNativeChain
                    : targetTokenConfig[selectedTargetChain].symbol
                }
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
                MenuProps={{
                  slotProps: {
                    paper: {
                      sx: {
                        borderRadius: theme.spacing(1.5),
                        backgroundColor: theme.palette.surface.silver,
                      },
                    },
                  },
                }}
                onChange={(event) => handleSetSelectedNativeChain(event.target.value as Chain)}
                IconComponent={mode === "BUY" ? () => null : undefined}>
                {/* Default Option */}
                {mode === "SELL" ? (
                  Object.keys(CHAIN_ABBREVIATION_MAP).map((chain) => (
                    <MenuItem
                      selected={selectedNativeChain === chain}
                      value={chain}
                      sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                      <img
                        src={CHAIN_BADGE_MAP[chain as Chain]}
                        alt={CHAIN_ABBREVIATION_MAP[chain as Chain]}
                        width={16}
                        height={16}
                      />
                      <Typography
                        sx={{ width: "100%", textAlign: "center" }}
                        variant="body-default"
                        color={theme.palette.text.secondary}>
                        {EVM_CHAINS.includes(chain as Chain)
                          ? CHAIN_ABBREVIATION_MAP["ethereum"]
                          : CHAIN_ABBREVIATION_MAP[chain as Chain]}
                      </Typography>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem
                    selected={true}
                    value={targetTokenConfig[selectedTargetChain].symbol}
                    sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                    <img
                      src={targetTokenConfig[selectedTargetChain].imageUrl}
                      alt={targetTokenConfig[selectedTargetChain].symbol}
                      width={16}
                      height={16}
                      style={{ borderRadius: "50%" }}
                    />
                    <Typography
                      sx={{ width: "100%", textAlign: "center" }}
                      variant="body-default"
                      color={theme.palette.text.secondary}>
                      {targetTokenConfig[selectedTargetChain].symbol}
                    </Typography>
                  </MenuItem>
                )}
              </StyledSelect>
            </Box>
          </Box>
        </Box>

        {/* Price Display */}
        <Box sx={{ marginTop: theme.spacing(1), marginX: "auto", textAlign: "center" }}>
          {/* Entered Value (USD) */}
          <Box>
            <Typography variant="display-md">{numberFormatter.format(+inputUsd)}</Typography>
          </Box>
          {/* Target Token (Amount) Received Preview */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography
              variant="body-md"
              color={theme.palette.text.secondary}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
              ≈{" "}
              <SmallNumberView
                value={mode === "BUY" ? targetAmount : nativeAmount}
                variants="body-md"
              />
              {mode === "BUY" ? (
                <img
                  src={targetTokenConfig[selectedTargetChain].imageUrl}
                  alt={targetTokenConfig[selectedTargetChain].symbol}
                  width={16}
                  height={16}
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={CHAIN_BADGE_MAP[selectedNativeChain as Chain]}
                  alt={nativeTokenConfig[selectedNativeChain].symbol}
                  width={16}
                  height={16}
                  style={{ margin: "auto" }}
                />
              )}{" "}
              {/* ({numberFormatter.format(price)} USD) */}
            </Typography>
          </Box>
        </Box>
        {/* Remindar */}
        {priceHint ? (
          priceHint
        ) : noValidRouteMessage ? (
          <RefetchPreview
            isLoading={isLoadingPriceImpact}
            isRefetching={isRefetchingPriceImpact}
            refetch={refetchPriceImpact}
            message={noValidRouteMessage}
          />
        ) : null}

        {/* Percentage */}
        <Box
          display={"flex"}
          gap={theme.spacing(1)}
          marginTop={theme.spacing(1)}
          width={"fit-content"}
          marginX={"auto"}>
          {buttonConfig.map((item) => (
            <RetroButton
              size="sm"
              key={item.label}
              onClick={() => {
                onPercentPress(item.value);
              }}>
              <Box sx={{ padding: theme.spacing(0.0, 2) }}>
                <Typography variant="body-default">{item.label}</Typography>
              </Box>
            </RetroButton>
          ))}
        </Box>

        {/* Numpad */}
        <Box
          marginTop={theme.spacing(1)}
          display={"flex"}
          flexWrap={"wrap"}
          width={"100%"}
          marginX={"auto"}
          maxWidth={"600px"}>
          {Array.from({ length: 9 }).map((_, index) => (
            <NumpadButton key={index} onClick={() => onNumpadPress(`${index + 1}` as NumpadKey)}>
              <Typography variant="title-h1">{index + 1}</Typography>
            </NumpadButton>
          ))}
          <NumpadButton onClick={() => onNumpadPress(".")}>
            <Typography variant="title-h1">.</Typography>
          </NumpadButton>
          <NumpadButton onClick={() => onNumpadPress("0")}>
            <Typography variant="title-h1">0</Typography>
          </NumpadButton>
          <NumpadButton
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            onClick={() => onNumpadPress("delete")}>
            <XSquare />
          </NumpadButton>
        </Box>

        {/* Confirm Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "4px",
          }}>
          <Typography variant="pre-title" color={theme.palette.text.secondary}>
            {priceImpact ? (
              <>
                -<SmallNumberView value={priceImpact} variants="pre-title" isUSD />
              </>
            ) : (
              "$--"
            )}{" "}
            net change • {slippage ?? "--"}% slippage
          </Typography>
        </Box>
        {EVM_CHAINS.includes(selectedTargetChain) && mode === "SELL" && !isApproveSucess ? (
          <RetroButton
            onClick={handleApproveAllowance}
            disabled={isApproving || isGetCurrentAllowanceLoading}>
            <Box sx={{ padding: theme.spacing(1.5, 3) }}>
              <Typography variant="title-h3">
                {isGetCurrentAllowanceLoading ? "Loading..." : "APPROVE SPENDING"}
              </Typography>
            </Box>
          </RetroButton>
        ) : (
          <RetroButton onClick={() => handleConfirm(slippage)} disabled={shouldConfirmDisabled}>
            <Box sx={{ padding: theme.spacing(1.5, 3) }}>
              <Typography variant="title-h3">CONFIRM</Typography>
            </Box>
          </RetroButton>
        )}
      </DarkDrawer>

      {/* Amount too small drawer */}
      <DarkDrawer
        open={shouldShowAmountTooSmall}
        anchor="bottom"
        onClose={() => {}}
        sx={{ zIndex: 200 }}>
        <Box>
          <Typography variant="title-h2">Amount is too small</Typography>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <Typography variant="body-md" color={theme.palette.text.secondary}>
            The minimum amount per deposit is ${MIN_ENTRY_USD}
          </Typography>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <RetroButton onClick={() => setShouldShowAmountTooSmall(false)}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              OK
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>

      {/* Confirm drawer */}
      <DarkDrawer
        open={isOpenConfirmDrawer}
        anchor="bottom"
        onClose={() => {}}
        sx={{ zIndex: 200 }}>
        <Box>
          <Typography variant="title-h2">CONFIRM</Typography>
        </Box>
        <Box
          sx={{
            marginTop: "16px",
            marginBottom: "32px",
          }}>
          <Typography variant="body-md" color={theme.palette.text.secondary}>
            You are {mode === "BUY" ? "purchasing" : "selling"} $
            {targetTokenConfig[selectedTargetChain].symbol} tokens with a total value of $
            {toFixedIfNecessary(inputUsd.toString(), 2)} ({targetAmount} $
            {targetTokenConfig[selectedTargetChain].symbol} @$
            {targetTokenPrice})
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}>
          <RetroButton variant="white" onClick={() => handleOpenConfirmDrawer(false)}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              NO
            </Typography>
          </RetroButton>
          <RetroButton onClick={() => onConfirm(slippage)}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              YES
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>

      {/* Slippage Drawer */}
      <DarkDrawer
        open={isOpenSlippageDrawer}
        anchor="bottom"
        onClose={() => {}}
        sx={{ zIndex: 200 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Typography variant="title-h2">Slippage</Typography>
        </Box>
        <Box
          sx={{
            marginTop: "16px",
          }}>
          <Box sx={{ position: "relative" }}>
            <StyledInputV2
              type="number"
              min={MIN_SLIPPAGE}
              max={MAX_SLIPPAGE}
              step="0.1"
              ref={slippageRef}
              onChange={handleSlippageChange}
              defaultValue={slippage}
            />
            <Typography
              variant="body-md"
              color={theme.palette.text.secondary}
              sx={{
                position: "absolute",
                right: "12px",
                top: "50%",
                transform: "translateY(-50%)",
              }}>
              %
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "32px",
          }}>
          <RetroButton
            variant="white"
            onClick={() => {
              handleConfirmSlippage();
              setIsOpenSlippageDrawer(false);
            }}
            disabled={!isSlippageValid}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              DONE
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>

      {/* Modal */}
      <RetroModal
        open={modalConfig !== null}
        onClose={() => {
          setModalConfig(null);
        }}
        variant={modalConfig?.variant ?? "success"}
        title={modalConfig?.title ?? ""}
        message={modalConfig?.message ?? ""}
      />
    </>
  );
};

const NumpadButton = styled("button")(({ theme }) => ({
  all: "unset",
  width: "33%",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  textAlign: "center",

  background: "transparent",
  border: "none",
  cursor: "pointer",
}));
