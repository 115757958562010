import { Box, MenuItem, Typography } from "@mui/material";
import {
  DepositDetailWrapper,
  DepositTextWrapper,
  StyledDepositDrawer,
  WalletAddressContainer,
} from "./styled";
import { CircleButton } from "components/CircleButton";
import { ReactComponent as TonTokenIcon } from "../../assets/icons/token-ton.svg";
import { ReactComponent as SolTokenIcon } from "../../assets/icons/token-sol.svg";
import { ReactComponent as EthTokenIcon } from "../../assets/icons/token-eth.svg";
import { ReactComponent as BaseTokenIcon } from "../../assets/icons/token-base.svg";
import { ReactComponent as GroupIcon } from "../../assets/icons/deposit-group.svg";
import SolanaIcon from "../../assets/icons/chains/solana.png";
import EthereumIcon from "../../assets/icons/chains/ethereum.png";
import TonIcon from "../../assets/icons/chains/ton.png";
import BaseIcon from "../../assets/icons/chains/base.png";
import { RetroButton } from "components/RetroButton";
import { copyToClipboard } from "utils";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { WalletAddresses } from "store/user-store-v2";
import { Close } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { Select } from "components/Select";
import { Chain } from "types";

export type TokenType = "TON" | "SOL" | "ETH" | "BASE";

interface TokenConfig {
  symbol: string;
  networkName: string;
  walletName: keyof WalletAddresses;
  icon: JSX.Element;
}

const configs: { [key in TokenType]: TokenConfig } = {
  TON: {
    symbol: "Toncoin ($TON)",
    networkName: "The Open",
    walletName: "tonAddress",
    icon: <TonTokenIcon />,
  },
  SOL: {
    symbol: "SOL",
    networkName: "SOLANA",
    walletName: "solAddress",
    icon: <SolTokenIcon />,
  },
  ETH: {
    symbol: "ETH",
    networkName: "ETHEREUM",
    walletName: "ethAddress",
    icon: <EthTokenIcon />,
  },
  BASE: {
    symbol: "ETH",
    networkName: "BASE",
    walletName: "ethAddress", // Pending API update to support Base wallet (turnkey)
    icon: <BaseTokenIcon />,
  },
};

export const DepositModal = ({
  tokenType,
  isOpen,
  close,
  onCopySuccess,
}: {
  tokenType: TokenType;
  isOpen: boolean;
  close: () => void;
  onCopySuccess?: () => void;
}) => {
  const { walletAddresses } = useUserStoreV2();
  const config = configs[tokenType];

  const handleCopy = () => {
    if (!walletAddresses) return;
    const walletAddress = walletAddresses[config.walletName as keyof WalletAddresses];
    if (walletAddress !== undefined) {
      const success = copyToClipboard(walletAddress);
      if (success) {
        onCopySuccess?.();
      }
    }
  };

  return (
    <StyledDepositDrawer anchor={"bottom"} open={isOpen} onClose={close}>
      <Box
        sx={{
          marginLeft: "auto",
          fontSize: "16px",
        }}>
        <CircleButton variant="white" onClick={close}>
          <Close fontSize="inherit" />
        </CircleButton>
      </Box>
      <DepositDetailWrapper>
        <Box>{configs[tokenType].icon}</Box>
        <Box>
          <Typography variant="display-md">DEPOSIT</Typography>
        </Box>
        <DepositTextWrapper>
          <Typography variant="body-lg" color="text.secondary">
            Sending only {configs[tokenType].symbol} on {config.networkName} Network to this
            address, or you might lose your funds.
          </Typography>
        </DepositTextWrapper>
        <WalletAddressContainer>
          <Typography variant="body-md" color="text.primary">
            {walletAddresses ? walletAddresses[config.walletName as keyof WalletAddresses] : ""}
          </Typography>
        </WalletAddressContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "300px",
            marginX: "auto",
          }}>
          <RetroButton variant="primary" onClick={handleCopy}>
            <Typography variant="title-h3" sx={{ padding: "12px 21.5px" }}>
              COPY ADDRESS
            </Typography>
          </RetroButton>
        </Box>
      </DepositDetailWrapper>
    </StyledDepositDrawer>
  );
};

interface CrossChainConfig {
  id: Chain;
  symbol: string;
  networkName: React.ReactNode;
  walletName: keyof WalletAddresses;
}

const crossChainConfigs: { [key in Chain]: CrossChainConfig } = {
  ton: {
    id: "ton",
    symbol: "Toncoin ($TON)",
    networkName: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <img src={TonIcon} alt="TON" />
        <Typography variant="body-md">The Open Network (TON)</Typography>
      </Box>
    ),
    walletName: "tonAddress",
  },
  solana: {
    id: "solana",
    symbol: "SOL",
    networkName: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <img src={SolanaIcon} alt="SOL" />
        <Typography variant="body-md">Solana</Typography>
      </Box>
    ),
    walletName: "solAddress",
  },
  ethereum: {
    id: "ethereum",
    symbol: "ETH",
    networkName: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <img src={EthereumIcon} alt="ETH" />
        <Typography variant="body-md">Ethereum</Typography>
      </Box>
    ),
    walletName: "ethAddress",
  },
  base: {
    id: "base",
    symbol: "ETH(BASE)",
    networkName: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <img src={BaseIcon} alt="BASE" />
        <Typography variant="body-md">Base</Typography>
      </Box>
    ),
    walletName: "ethAddress",
  },
};

export const CrossChainDepositModal = ({
  defaultChain = "ethereum",
  chains,
  isOpen,
  close,
  onCopySuccess,
}: {
  defaultChain: Chain;
  chains: Array<Chain>;
  isOpen: boolean;
  close: () => void;
  onCopySuccess?: () => void;
}) => {
  const [selectedChain, setSelectedChain] = useState<Chain>(defaultChain);
  const { walletAddresses } = useUserStoreV2();

  const sortedConfigs = useMemo(() => {
    const config = chains
      // Map to cross chain config
      .map((chains) => {
        return crossChainConfigs[chains];
      })
      // Sort by default token type
      .sort((a, b) => {
        const isAMatch = a.id === defaultChain ? 1 : -1;
        const isBMatch = b.id === defaultChain ? 1 : -1;

        return isAMatch - isBMatch;
      });
    return config;
  }, [chains, defaultChain]);

  const supporttedChainList = useMemo(() => {
    const result = sortedConfigs.map((config) => {
      return config.symbol;
    });
    return result;
  }, [sortedConfigs]);

  const handleCopy = () => {
    if (!walletAddresses) return;
    const walletAddress = walletAddresses[crossChainConfigs[selectedChain].walletName];
    if (walletAddress !== undefined) {
      const success = copyToClipboard(walletAddress);
      if (success) {
        onCopySuccess?.();
      }
    }
  };

  return (
    <StyledDepositDrawer anchor={"bottom"} open={isOpen} onClose={close}>
      <Box
        sx={{
          marginLeft: "auto",
          fontSize: "16px",
        }}>
        <CircleButton variant="white" onClick={close}>
          <Close fontSize="inherit" />
        </CircleButton>
      </Box>
      <DepositDetailWrapper>
        <Box>
          <GroupIcon />
        </Box>
        <Box>
          <Typography variant="display-md">DEPOSIT</Typography>
        </Box>
        <DepositTextWrapper>
          <Typography variant="body-lg" color="text.secondary">
            Cabal Town supports cross chains swap of tokens. Deposit{" "}
            {supporttedChainList.join(", ")} only.
          </Typography>
        </DepositTextWrapper>
        <Select
          value={selectedChain}
          onChange={(event) => setSelectedChain(event.target.value as Chain)}
          sx={{ width: "100%", marginBottom: "16px" }}>
          {sortedConfigs.map((option, index) => {
            return (
              <MenuItem key={`cross-chain-modal-select-${index}`} value={option.id}>
                {option.networkName}
              </MenuItem>
            );
          })}
        </Select>
        <WalletAddressContainer>
          <Typography variant="body-md" color="text.primary">
            {walletAddresses
              ? walletAddresses[crossChainConfigs[selectedChain].walletName]
              : "Loading..."}
          </Typography>
        </WalletAddressContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "300px",
            marginTop: "12px",
          }}>
          <RetroButton variant="primary" onClick={handleCopy}>
            <Typography variant="title-h3" sx={{ padding: "12px 21.5px" }}>
              COPY ADDRESS
            </Typography>
          </RetroButton>
        </Box>
      </DepositDetailWrapper>
    </StyledDepositDrawer>
  );
};
