import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { createContext, useEffect, useState } from "react";
import { ROUTES } from "consts";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { DeployerPage, Jetton, CreatePage, ExplorerPage } from "pages";
import { Footer } from "components/footer";
import { Header } from "components/header";
import { FaqPage } from "pages/faq";
import { ProfilePage } from "pages/profile";
import { useJettonLogo } from "hooks/useJettonLogo";
import { useAuthToken } from "hooks/useAuthToken";
import { OnboardingPage } from "pages/onboarding";
import { QuestPage } from "pages/quest";
import { CallPage } from "pages/call";
import { PortfolioPage } from "pages/portfolio";
import { CabalIdPage } from "pages/cabal-id";
import { TokenIdPage } from "pages/token-id";
import useNotification from "hooks/useNotification";
import analytics from "services/analytics";
import axiosService from "services/axios";
import useUserStore from "store/user-store/useUserStore";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Container } from "components/container";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { useConsumeStartParams } from "hooks/useConsumeStartParams";
// import eruda from "eruda";

// eruda.init();

analytics.init();
const ExclueFooterRoutes = [ROUTES.gated, ROUTES.onboarding];

const AppWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100dvh",
}));

const FooterBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  flex: "0 0 auto",
}));

const ScreensWrapper = styled(Box)({
  flex: "1 1 auto",
  overflowY: "auto",
  overflowX: "hidden",
  width: "100%",
  "-ms-overflow-style": "none" /* Internet Explorer 10+ */,
  "scrollbar-width": "none" /* Firefox */,

  "*::-webkit-scrollbar": {
    display: "none",
  },
  "*::-webkit-scrollbar-track": {
    display: "none",
  },
  "*::-webkit-scrollbar-thumb": {
    display: "none",
  },

  display: "flex",
  flexDirection: "column",
});

export const EnvContext = createContext({
  isSandbox: false,
  isTestnet: false,
});

const PageNotFound = () => {
  const { showNotification } = useNotification();

  useEffect(() => {
    showNotification("Page not found", "error");
  }, [showNotification]);

  return <Box />;
};

interface ContentWrapperProps {
  children?: any;
}

const ContentWrapper = ({ children }: ContentWrapperProps) => {
  return (
    <Container flex={1} minHeight={0}>
      {children}
      <Outlet />
    </Container>
  );
};
const ProtectedRoute = () => {
  const { accessToken, refreshToken, setTokens, isInitialized } = useAuthToken();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  useEffect(() => {
    async function verifyToken() {
      if (isInitialized) {
        if (accessToken && refreshToken) {
          try {
            const { res } = await axiosService.verifyToken(refreshToken);
            setIsAuthenticated(res.status === "success");
          } catch (error) {
            console.error("Error verifying token:", error);
            setIsAuthenticated(false);
            setTokens();
          }
        }
      }
    }
    verifyToken();
  }, [accessToken, refreshToken, isInitialized, setTokens]);

  return isAuthenticated ? <Outlet /> : <Navigate to={ROUTES.gated} />;
};

declare global {
  interface Window {
    Telegram: any;
  }
}

const queryClient = new QueryClient();
const App = () => {
  const { resetJetton } = useJettonLogo();
  const { getTgUserIdV2 } = useUserStoreV2();
  const location = useLocation();
  useConsumeStartParams();

  useEffect(() => {
    const init = async () => {
      try {
        resetJetton();
        await getTgUserIdV2(false);
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [getTgUserIdV2, resetJetton]);

  return (
    <AppWrapper>
      <QueryClientProvider client={queryClient}>
        <EnvContext.Provider
          value={{
            isSandbox: window.location.search.includes("sandbox"),
            isTestnet: window.location.search.includes("testnet"),
          }}>
          <ScreensWrapper>
            <Routes>
              <Route
                path="*"
                element={
                  <>
                    <Header />
                    <Navigate to="/" />
                    <PageNotFound />
                  </>
                }
              />
              <Route path={ROUTES.gated} element={<Navigate to={ROUTES.explorer} />} />
              <Route path="/" element={<Header />}>
                <Route path={"/"} element={<ProtectedRoute />}>
                  {/* don't want to apply content wrapper here */}
                  <Route path={ROUTES.deployer} element={<DeployerPage />} />
                  <Route path="/" element={<ContentWrapper />}>
                    <Route path={ROUTES.call} element={<CallPage />} />
                    <Route path={ROUTES.portfolio} element={<PortfolioPage />} />
                    <Route path={ROUTES.create} element={<CreatePage />} />
                    <Route path={ROUTES.explorer} element={<ExplorerPage />} />
                    {/* <Route path={ROUTES.explore} element={<ExplorePage />} /> */}
                    {/* Old Routes */}
                    {/* <Route path={ROUTES.explorer} element={<ExplorerPage />} /> */}
                    <Route path={ROUTES.jettonId} element={<Jetton />} />
                    <Route path={ROUTES.profile} element={<ProfilePage />} />
                    <Route path={ROUTES.faq} element={<FaqPage />} />
                    <Route path={ROUTES.onboarding} element={<OnboardingPage />} />
                    <Route path={ROUTES.quest} element={<QuestPage />} />
                    <Route path={ROUTES.cabalId} element={<CabalIdPage />} />
                    <Route path={ROUTES.tokenId} element={<TokenIdPage />} />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </ScreensWrapper>
          {!ExclueFooterRoutes.includes(location.pathname) && (
            <FooterBox>
              <Footer />
            </FooterBox>
          )}
        </EnvContext.Provider>
      </QueryClientProvider>
    </AppWrapper>
  );
};

export default App;
