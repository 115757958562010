import { Box, Typography, useTheme } from "@mui/material";
import { LoadingView } from "components/LoadingView";
import { JoinedTokenCallsEmptyView, TrendingTokenCallsEmptyView } from "components/EmptyView";
import { RetroCard } from "components/RetroCard";
import { ScrollableBox } from "components/ScrollableBox";
import { Select } from "components/Select";
import { useEffect, useRef, useState } from "react";
import {
  TokenCallHeader,
  TokenCallRow,
  TrendingTokenCallHeader,
  TrendingTokenCallRow,
} from "components/TokenCall";
import { DefaultLoginBlockerContent, LoginBlockerWrapper } from "components/LoginBlocker";
import Pagination from "components/Pagination";
import { Container } from "components/container";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { useInViewInfiniteQuery } from "hooks/useInViewInfiniteQuery";
import { LoadMoreRow } from "components/LoadMoreRow";
import { RetroButton } from "components/RetroButton";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { ReactComponent as HelpIcon } from "../../assets/icons/warning/circle_help.svg";
import { useOnClickOutside } from "usehooks-ts";

const UnauthorizedView = ({ login }: { login: () => void }) => {
  return (
    <RetroCard>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "20px",
        }}>
        <LogoIcon />
        <Box
          sx={{
            textAlign: "center",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "24px",
          }}>
          <DefaultLoginBlockerContent login={login} />
        </Box>
      </Box>
    </RetroCard>
  );
};

const JoinedTokenCalls = () => {
  const theme = useTheme();

  const { tgUserId, tgUserName, getTgUserIdV2 } = useUserStoreV2();

  const handleLogin = async () => {
    try {
      if (!tgUserId || !tgUserName) {
        await getTgUserIdV2();
      }
    } catch (error) {
      console.error("Error while logging in", error);
    }
  };

  const [page, setPage] = useState(1);

  const queryResult = useInfiniteQuery({
    queryKey: ["cabal-calls", { page }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getJoinedCabalCalls({
        limit: 10,
        page: pageParam,
        field: "joinedCabals",
        selects: [
          "name",
          "symbol",
          "iconUri",
          "confidenceInterval",
          "createdAt",
          "updatedAt",
          "userId",
          "groupId",
          "chain",
          "jetton",
          "address",
        ],
      });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { data, isLoading: isLoadingJoinedCabalCalls } = queryResult;
  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  if (!tgUserId || !tgUserName) {
    return <UnauthorizedView login={handleLogin} />;
  }

  if (isLoadingJoinedCabalCalls) {
    return <LoadingView />;
  }

  // If the user has joined a group but there are no token calls
  if (!data || !data.pages[0].results || data.pages[0].results.length === 0) {
    return <JoinedTokenCallsEmptyView />;
  }

  // If there are token calls
  return (
    <LoginBlockerWrapper isLoginedIn={!!tgUserId && !!tgUserName} fallback={<></>}>
      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(2.5),
            gap: theme.spacing(1),
            height: "100%",
          }}>
          <Typography variant="title-h3" color={theme.palette.text.primary}>
            Token Calls By Alpha Groups You Joined
          </Typography>
          <TokenCallHeader />
          <ScrollableBox>
            {data.pages
              .flatMap((page) => page.results)
              .map((tokenCall: any) => (
                <Box key={tokenCall._id} sx={{ marginTop: theme.spacing(0.5) }}>
                  <TokenCallRow tokenCall={tokenCall} />
                </Box>
              ))}
            {!hasAllFetched && <LoadMoreRow ref={ref} />}
          </ScrollableBox>
        </Box>
      </RetroCard>
    </LoginBlockerWrapper>
  );
};

const TrendingTokenCalls = () => {
  const theme = useTheme();
  const [page, setPage] = useState(1);

  const queryResult = useInfiniteQuery({
    queryKey: ["trending-calls", { page }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getCabalCalls({
        limit: 10,
        page: pageParam,
        selects: [
          "symbol",
          "groupId",
          "address",
          "createdAt",
          "name",
          "iconUri",
          "chain",
          "confidenceInterval",
        ],
      });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { data, isLoading } = queryResult;
  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  if (isLoading) {
    return <LoadingView />;
  }

  if (!data || !data.pages[0].results || data.pages[0].results.length === 0) {
    return <TrendingTokenCallsEmptyView />;
  }
  return (
    <RetroCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: theme.spacing(2.5),
          gap: theme.spacing(1),
          height: "100%",
        }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography variant="title-h3" color={theme.palette.text.primary}>
            DAILY BEST CALLS
          </Typography>
          <TrendingCabalTooltip />
        </Box>
        <TrendingTokenCallHeader />
        <ScrollableBox>
          {data.pages
            .flatMap((page) => page.results)
            .map((tokenCall: any) => (
              <Box key={tokenCall._id} sx={{ marginTop: theme.spacing(0.5) }}>
                <TrendingTokenCallRow tokenCall={tokenCall} />
              </Box>
            ))}
          {!hasAllFetched && <LoadMoreRow ref={ref} />}
        </ScrollableBox>
      </Box>
    </RetroCard>
  );
};

const CallPage = () => {
  const theme = useTheme();
  const { isShowHeader, setIsShowHeader } = useLayoutConfigStore();

  const [tab, setTab] = useState<"trending" | "joined-cabal">("trending");

  useEffect(() => {
    if (!isShowHeader) {
      setIsShowHeader(true);
    }
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1), height: "100%" }}>
      <Box
        sx={{
          background: theme.palette.surface.container,
          borderRadius: theme.spacing(1),
          border: `1px solid ${theme.palette.border.dark}`,
        }}>
        <Box
          sx={{
            padding: theme.spacing(1),
            height: "70px",
            display: "flex",
            gap: theme.spacing(1),
          }}>
          <RetroButton onClick={() => setTab("trending")} disabled={tab === "trending"}>
            <Typography variant="body-md">Featured Alpha</Typography>
          </RetroButton>
          <RetroButton onClick={() => setTab("joined-cabal")} disabled={tab === "joined-cabal"}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                paddingX: "4px",
              }}>
              <Typography variant="body-md">Alpha From</Typography>
              <Typography variant="body-md">Your Groups</Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
      {tab === "joined-cabal" && <JoinedTokenCalls />}
      {tab === "trending" && <TrendingTokenCalls />}
    </Box>
  );
};

const TrendingCabalTooltip = () => {
  const [show, setShow] = useState(false);
  const bondingCurveTooltipRef = useRef(null);
  const theme = useTheme();
  useOnClickOutside(bondingCurveTooltipRef, () => setShow(false));

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        width: "fit-content",
        cursor: "pointer",
      }}
      ref={bondingCurveTooltipRef}
      onClick={handleClick}>
      <HelpIcon />
      <Box
        sx={{
          display: show ? "block" : "none",
          position: "absolute",
          bottom: "-140px",
          left: "-25px",
          transform: "translateX(-50%)",
          zIndex: "1000",
          width: "300px",
          backgroundColor: theme.palette.surface["silver"],
          padding: theme.spacing(2),
          borderRadius: theme.spacing(2),
          border: `1px solid ${theme.palette.border.dark}`,
        }}>
        <Typography variant="body-md">
          The best-performing token calls from all alpha groups, delayed by 24 hours. Join a group
          to access real-time token calls.
        </Typography>
      </Box>
    </Box>
  );
};
export { CallPage };
