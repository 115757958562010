import { Box, Typography } from "@mui/material";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { RetroCard } from "components/RetroCard";
import { ReactNode } from "react";
import { RetroButton } from "components/RetroButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "consts";

const EmptyView = ({
  title,
  message,
  actionButton,
}: {
  title: string | ReactNode;
  message: string | ReactNode;
  actionButton?: ReactNode;
}) => {
  return (
    <RetroCard>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "20px",
        }}>
        <LogoIcon />
        <Box
          sx={{
            textAlign: "center",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "24px",
          }}>
          <Typography variant="title-h2">{title}</Typography>
          <Typography variant="body-lg">{message}</Typography>
          {actionButton ?? null}
        </Box>
      </Box>
    </RetroCard>
  );
};

const CabalListEmptyView = () => {
  const navigate = useNavigate();
  const navigateToCabals = () => {
    navigate(ROUTES.explorer);
  };

  return (
    <EmptyView
      title="Nothing to see here!"
      message="Please come back later."
      actionButton={
        <EmptyViewActionButton onClick={navigateToCabals}>Explore Groups</EmptyViewActionButton>
      }
    />
  );
};

const JoinedTokenCallsEmptyView = () => {
  const navigate = useNavigate();
  const navigateToCabals = () => {
    navigate(ROUTES.explorer);
  };

  return (
    <EmptyView
      title="no token calls"
      message="You either have not joined any groups, or your groups have not made any calls."
      actionButton={
        <EmptyViewActionButton onClick={navigateToCabals}>Explore Groups</EmptyViewActionButton>
      }
    />
  );
};

const TrendingTokenCallsEmptyView = () => {
  const navigate = useNavigate();
  const navigateToCabals = () => {
    navigate(ROUTES.explorer);
  };

  return (
    <EmptyView
      title="Nothing to see here!"
      message="Please come back later."
      actionButton={
        <EmptyViewActionButton onClick={navigateToCabals}>Explore Groups</EmptyViewActionButton>
      }
    />
  );
};

const GenericEmptyView = () => {
  const navigate = useNavigate();
  const navigateToCabals = () => {
    navigate(ROUTES.explorer);
  };

  return (
    <EmptyView
      title="Oops…"
      message="Something is wrong. Please come back later."
      actionButton={
        <EmptyViewActionButton onClick={navigateToCabals}>Explore Groups</EmptyViewActionButton>
      }
    />
  );
};

const EmptyViewActionButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: string | ReactNode;
}) => {
  return (
    <RetroButton id="telegram-login" onClick={onClick}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "12px",
          padding: "12px",
        }}>
        <Typography variant="title-h3">{children}</Typography>
      </Box>
    </RetroButton>
  );
};

export {
  EmptyView,
  TrendingTokenCallsEmptyView,
  EmptyViewActionButton,
  JoinedTokenCallsEmptyView,
  CabalListEmptyView,
  GenericEmptyView,
};
