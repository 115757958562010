import { useEffect, useRef } from "react";

export const useSignalAbort = () => {
  const controller = new AbortController();
  const abortControllerRef = useRef<AbortController>(controller);

  useEffect(() => {
    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  const getSignal = () => {
    return abortControllerRef.current.signal;
  };

  const abortSignal = () => {
    abortControllerRef.current.abort();
  };

  return {
    getSignal,
    abortSignal,
  };
};
