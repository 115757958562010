import { Box, Drawer, DrawerProps, styled, useTheme } from "@mui/material";

export const CHAIN_ABBREVIATION_MAP: Record<string, string> = {
  solana: "SOL",
  ethereum: "ETH",
  base: "BASE",
  ton: "TON",
  ton_testnet: "TON",
};

export const NATIVE_TOKEN_ADDRESS_MAP: Record<"solana" | "ethereum" | "base", string> = {
  solana: "So11111111111111111111111111111111111111112",
  base: "0x4200000000000000000000000000000000000006",
  ethereum: "0xEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE",
};

export type NumpadKey = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "0" | "." | "delete";

const StyledDrawer = styled(Drawer)({
  background: "transparent",
  "& .MuiDrawer-paper": {
    background: "transparent",
    display: "flex",
  },
});

export const buttonConfig = [
  {
    label: "10%",
    value: 0.1,
  },
  {
    label: "25%",
    value: 0.25,
  },
  {
    label: "50%",
    value: 0.5,
  },
  {
    label: "MAX",
    value: 1,
  },
];

export const DarkDrawer = ({ children, ...props }: DrawerProps) => {
  const theme = useTheme();
  return (
    <StyledDrawer {...props}>
      <Box
        sx={{
          background: theme.palette.surface.container,
          border: `1px solid ${theme.palette.border.dark}`,
          borderTopLeftRadius: theme.spacing(3),
          borderTopRightRadius: theme.spacing(3),
          padding: theme.spacing(2.5),
          flex: 1,
        }}>
        {children}
      </Box>
    </StyledDrawer>
  );
};
