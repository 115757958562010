import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  DrawerHeader,
  WidgeWrapper,
  StyledDrawer,
  EstimatePriceWrapper,
  ChartWrapper,
  StyledSlider,
  CurveSteepnessButtonGroup,
} from "./styles";
import { Box, IconButton, Typography } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import { ReactComponent as BackArrow } from "../../../assets/icons/arrow-left.svg";
import { RetroButton } from "components/RetroButton";
import { Steepness } from "../CreateCabalV2";
import { sleep } from "lib/utils";
import { SteepnessChart } from "components/Chart/SteepnessChart";
import { useChart } from "hooks/useChart";
import { MouseEventParams, Time } from "lightweight-charts";
import { abbreviateNumber } from "utils";
import { PercentageBrick } from "components/PercentageBrick";
import { Mark } from "@mui/material/Slider/useSlider.types";

const SliderMarks: Record<Steepness, Mark[]> = {
  Casual: [{ value: 1 }, { value: 2000 }],
  Standard: [{ value: 1 }, { value: 300 }],
  Exclusive: [{ value: 1 }, { value: 100 }],
};

const MaxSliderSteps: Record<Steepness, number> = {
  Casual: 2000,
  Standard: 300,
  Exclusive: 100,
};

const initialData = {
  Casual: require("../data/casual.json"),
  Standard: require("../data/standard.json").slice(0, MaxSliderSteps["Standard"]),
  Exclusive: require("../data/exclusive.json").slice(0, MaxSliderSteps["Exclusive"]),
};

const minEntryPrices = {
  Casual: 0.93,
  Standard: 17.28,
  Exclusive: 39.98,
};

export const SteepnessPreview = ({
  children,
  isOpenned,
  selectedSteepness,
  close,
  handleSelectCurveSteepness,
}: {
  children?: ReactNode;
  isOpenned: boolean;
  selectedSteepness: Steepness;
  close: () => void;
  handleSelectCurveSteepness: (steepness: Steepness) => void;
}) => {
  const [sliderValues, setSliderValues] = useState<Record<Steepness, number>>({
    Casual: MaxSliderSteps["Casual"] / 2,
    Standard: MaxSliderSteps["Standard"] / 2,
    Exclusive: MaxSliderSteps["Exclusive"] / 2,
  });

  const {
    chartRef,
    seriesRef,
    isMounted,
    updateCrosshairPosition,
    subscribeCrosshairMove,
    unsubscribeCrosshairMove,
    setIsMounted,
  } = useChart();

  useEffect(() => {
    const handleOnCrosshairMove = (params: MouseEventParams<Time>) => {
      if (Array.from(params.seriesData.entries()) && Array.from(params.seriesData.entries())[0]) {
        const result = Array.from(params.seriesData.entries())[0][1] as {
          time: number;
          value: number;
        };
        setSliderValues((pre) => ({
          ...pre,
          [selectedSteepness]: result.time,
        }));
      }
    };

    if (isMounted && selectedSteepness) {
      setSliderValues((pre) => ({
        Casual: MaxSliderSteps["Casual"] / 2,
        Standard: MaxSliderSteps["Standard"] / 2,
        Exclusive: MaxSliderSteps["Exclusive"] / 2,
      }));
      unsubscribeCrosshairMove(handleOnCrosshairMove);
      subscribeCrosshairMove(handleOnCrosshairMove);
    }
  }, [isMounted, selectedSteepness]);

  const handleSliderChange = (_: any, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      if (seriesRef.current) {
        updateCrosshairPosition(newValue, newValue, seriesRef.current["steepness"].line);
      }
      setSliderValues((pre) => ({
        ...pre,
        [selectedSteepness]: newValue,
      }));
    }
  };

  const handleOnClose = async () => {
    close();
    await sleep(500);
    setSliderValues((pre) => ({
      Casual: MaxSliderSteps["Casual"] / 2,
      Standard: MaxSliderSteps["Standard"] / 2,
      Exclusive: MaxSliderSteps["Exclusive"] / 2,
    }));
  };

  return (
    <div>
      <div>
        <React.Fragment key={"bottom"}>
          {children}
          <StyledDrawer anchor={"bottom"} open={isOpenned} onClose={handleOnClose}>
            <DrawerHeader>
              <IconButton sx={{ padding: "0", cursor: "pointer" }} onClick={handleOnClose}>
                <BackArrow />
                <Typography variant="button-md" color="text.secondary">
                  BACK
                </Typography>
              </IconButton>
            </DrawerHeader>
            <RetroCard>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  padding: "20px",
                  gap: "16px",
                }}>
                <WidgeWrapper $align="center">
                  <Typography variant="pre-title" color="text.secondary">
                    MINIMUM ENTRY PRICE
                  </Typography>
                  <EstimatePriceWrapper>
                    <Typography variant="title-h2">
                      ${initialData[selectedSteepness][sliderValues[selectedSteepness] - 1].value}
                    </Typography>
                    <PercentageBrick
                      percentage={
                        initialData[selectedSteepness][sliderValues[selectedSteepness] - 1].value /
                          initialData[selectedSteepness][0].value -
                        1
                      }
                    />
                  </EstimatePriceWrapper>
                </WidgeWrapper>
                <ChartWrapper>
                  <SteepnessChart
                    data={initialData[selectedSteepness]}
                    chartRef={chartRef}
                    seriesRef={seriesRef}
                    setIsMounted={setIsMounted}
                  />
                </ChartWrapper>
                <WidgeWrapper $align="center">
                  <Typography variant="pre-title" color="text.secondary">
                    NUMBER OF MEMBER
                  </Typography>
                  {selectedSteepness === "Casual" ? (
                    <StyledSlider
                      aria-label="MEMBER"
                      defaultValue={MaxSliderSteps["Casual"] / 2}
                      valueLabelDisplay="off"
                      step={1}
                      min={1}
                      max={MaxSliderSteps["Casual"]}
                      marks={SliderMarks["Casual"]}
                      onChange={handleSliderChange}
                    />
                  ) : null}
                  {selectedSteepness === "Standard" ? (
                    <StyledSlider
                      aria-label="MEMBER"
                      defaultValue={MaxSliderSteps["Standard"] / 2}
                      valueLabelDisplay="off"
                      step={1}
                      min={1}
                      max={MaxSliderSteps["Standard"]}
                      marks={SliderMarks["Standard"]}
                      onChange={handleSliderChange}
                    />
                  ) : null}
                  {selectedSteepness === "Exclusive" ? (
                    <StyledSlider
                      aria-label="MEMBER"
                      defaultValue={MaxSliderSteps["Exclusive"] / 2}
                      valueLabelDisplay="off"
                      step={1}
                      min={1}
                      max={MaxSliderSteps["Exclusive"]}
                      marks={SliderMarks["Exclusive"]}
                      onChange={handleSliderChange}
                    />
                  ) : null}
                  <Typography variant="title-h2">{sliderValues[selectedSteepness]}</Typography>
                  <Typography variant="body-sm">
                    Each member has to hold 10K tokens to join an alpha group
                  </Typography>
                </WidgeWrapper>
                <Box
                  sx={{
                    height: "fit",
                  }}>
                  <CurveSteepnessButtonGroup>
                    <Box
                      sx={{
                        width: "100%",
                        padding: "6px 6px",
                        display: "flex",
                        gap: "6px",
                        height: "82px",
                      }}>
                      {["Casual", "Standard", "Exclusive"].map((steepness) => {
                        return (
                          <RetroButton
                            id={steepness}
                            onClick={() => {
                              handleSelectCurveSteepness(steepness as Steepness);
                            }}
                            disabled={steepness === selectedSteepness}>
                            <Typography variant="body-md">{steepness}</Typography>
                          </RetroButton>
                        );
                      })}
                    </Box>
                  </CurveSteepnessButtonGroup>
                </Box>
              </Box>
            </RetroCard>
          </StyledDrawer>
        </React.Fragment>
      </div>
    </div>
  );
};
