import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import axiosService from "services/axios";
import useTradeStore from "store/trade-store.ts/useTradeStore";
import { WalletAddresses } from "store/user-store-v2";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { Chain } from "types";

export const EVM_CHAINS = ["ethereum", "base"];

export const NATIVE_TOKEN_ADDRESS_MAP: Record<"solana" | "ethereum" | "base" | "ton", string> = {
  solana: "So11111111111111111111111111111111111111112",
  base: "0x4200000000000000000000000000000000000006",
  ethereum: "0xEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE",
  ton: "EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c",
};

const NATIVE_TOKEN_CONFIG: Record<string, TokenConfig> = {
  solana: {
    chain: "solana",
    symbol: "SOL",
    token: NATIVE_TOKEN_ADDRESS_MAP["solana"],
  },
  ethereum: {
    chain: "ethereum",
    symbol: "ETH",
    token: NATIVE_TOKEN_ADDRESS_MAP["ethereum"],
  },
  base: {
    chain: "base",
    symbol: "ETH",
    token: NATIVE_TOKEN_ADDRESS_MAP["base"],
  },
  ton: {
    chain: "ton",
    symbol: "TON",
    token: NATIVE_TOKEN_ADDRESS_MAP["solana"],
  },
};

const CHAIN_WALLET_MAP: Record<string, keyof WalletAddresses> = {
  ton: "tonAddress",
  solana: "solAddress",
  ethereum: "ethAddress",
  base: "ethAddress",
};

export const CHAIN_ABBREVIATION_MAP: Record<string, string> = {
  ton: "TON",
  solana: "SOL",
  ethereum: "ETH",
  base: "BASE",
};

export const nativePriceMap: Record<Chain, string> = {
  solana: "solPrice",
  ethereum: "ethPrice",
  base: "ethPrice",
  ton: "tonPrice",
};

export interface ITradeContext {
  mode: "BUY" | "SELL" | "SWAP" | "UNWRAP";
  selectedNativeChain: Chain;
  jettonBuyRatio: number | null;
  jettonSellRatio: number | null;
  nativeTokenConfig: Record<string, TokenConfig>;
  nativeTokenPrice: number | null;
  nativeBalanceUsd: number | null;
  nativeBalanceAmount: number | null;
  isNativeTokenPriceLoading: boolean;
  isNativeTokenPriceRefetching: boolean;
  selectedTargetChain: Chain;
  targetTokenConfig: Record<string, TokenConfig>;
  targetTokenPrice: number | null;
  targetBalanceUsd: number | null;
  targetBalanceAmount: number | null;
  targetBalancePerformance: number | null;
  targetBalanceProfit: number | null;
  isTargetTokenBalanceLoading: boolean;
  isTargetTokenBalanceRefetching: boolean;
  nativeToTargetTokenRatio: number | null;
  isTradeInitingRef: React.MutableRefObject<boolean>;
  isTradeIniting: boolean;
  senderAddress: string;
  shouldTradeDrawerOpen: boolean;
  isDepositModalOpen: boolean;
  isTrading: boolean;
  tgGroupId?: number | null;
  destinationAddress?: string | null;
  getNativePriceByChain: (chain: Chain) => number | null;
  refetchPriceRatio: () => void;
  refetchTargetTokenBalance: () => Promise<QueryObserverResult<any, Error>>;
  handleSetMode: (mode: "BUY" | "SELL" | "SWAP" | "UNWRAP") => Promise<void>;
  handleSetIsTrading: (isTrading: boolean) => void;
  handleSetSelectedNativeChain: (chain: Chain) => void;
  handleSetSelectedTargetChain: (chain: Chain) => void;
  isPolling: boolean;
  setIsPolling: (isPolling: boolean) => void;
  isTonBoardcasting: boolean;
  setIsTonBoardcasting: (isTonBoardcasting: boolean) => void;
}

export const TradeContext = createContext<ITradeContext>({
  mode: "BUY",
  selectedNativeChain: "ton",
  jettonBuyRatio: null,
  jettonSellRatio: null,
  nativeTokenConfig: {},
  nativeTokenPrice: null,
  nativeBalanceUsd: null,
  nativeBalanceAmount: null,
  isNativeTokenPriceLoading: false,
  isNativeTokenPriceRefetching: false,
  selectedTargetChain: "ethereum",
  targetTokenConfig: {},
  targetTokenPrice: null,
  targetBalanceUsd: null,
  targetBalanceAmount: null,
  targetBalancePerformance: null,
  targetBalanceProfit: null,
  isTargetTokenBalanceLoading: false,
  isTargetTokenBalanceRefetching: false,
  nativeToTargetTokenRatio: null,
  senderAddress: "",
  isTradeInitingRef: { current: false },
  isTradeIniting: false,
  shouldTradeDrawerOpen: false,
  isDepositModalOpen: false,
  isTrading: false,
  tgGroupId: null,
  destinationAddress: null,
  getNativePriceByChain: () => null,
  refetchPriceRatio: () => {},
  refetchTargetTokenBalance: () => Promise.resolve({} as QueryObserverResult<any, Error>),
  handleSetMode: () => Promise.resolve(),
  handleSetIsTrading: () => {},
  handleSetSelectedNativeChain: () => {},
  handleSetSelectedTargetChain: () => {},
  isPolling: false,
  setIsPolling: () => {},
  isTonBoardcasting: false,
  setIsTonBoardcasting: () => {},
});

export interface ITradeConfig {
  destToken: string;
  srcToken: string;
  destChains: string[];
  nativeChains: string[];
}

export interface TokenConfig {
  chain: Chain;
  symbol: string;
  token?: string;
  imageUrl?: string;
}

export interface ITradeProps {
  chain: Chain;
  mode?: "BUY" | "SELL" | "SWAP" | "UNWRAP";
  nativeTokenConfig?: Record<string, TokenConfig>;
  targetTokenConfig?: Record<string, TokenConfig>;
  children: React.ReactNode;
}

export function TradeProvider(props: ITradeProps) {
  // Data
  const [mode, setMode] = useState<"BUY" | "SELL" | "SWAP" | "UNWRAP">(props.mode ?? "BUY");
  const [isPolling, setIsPolling] = useState(false);
  const [isTonBoardcasting, setIsTonBoardcasting] = useState(false);
  const {
    chain,
    nativeTokenConfig = NATIVE_TOKEN_CONFIG,
    targetTokenConfig = NATIVE_TOKEN_CONFIG,
  } = props;

  // Chains List
  // const nativeChains = Object.keys(nativeTokenConfig);
  // const targetChains = Object.keys(targetTokenConfig);

  // Current chain
  const [selectedNativeChain, setSelectedNativeChain] = useState<Chain>(chain);
  const [selectedTargetChain, setSelectedTargetChain] = useState<Chain>(chain);

  // User
  const { walletAddresses, createWallet, hasWallet } = useUserStoreV2();

  // Flags
  const [isTrading, setIsTrading] = useState(false);
  const [isTradeIniting, setIsTradeIniting] = useState(false);
  const isTradeInitingRef = useRef(false);

  // Deposit Modal
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);

  // Trade Drawer
  const [shouldTradeDrawerOpen, setShouldTradeDrawerOpen] = useState(false);

  const fetchBalanceTypeRef = useRef<"realTime" | "archive">("archive");

  // Balance
  const { isLoading: isLoadingUserBalance, getUserBalance, updateUserBalance } = useTradeStore();

  // Sender Address
  const senderAddress =
    walletAddresses?.[
      CHAIN_WALLET_MAP[
        (mode === "BUY" || mode === "SWAP" ? selectedNativeChain : selectedTargetChain) as Chain
      ]
    ] ?? "";

  useEffect(() => {
    if (!isLoadingUserBalance && getUserBalance(chain, "amount") !== null) {
      const defaultChainNativeAmount = getUserBalance(chain, "amount");

      if (defaultChainNativeAmount! <= 0) {
        const balanceList = ["ethereum", "solana", "base"].map((chain) => {
          return {
            chain,
            amount: getUserBalance(chain, "amount"),
          };
        });

        if (balanceList.every((balance) => balance.amount === null || balance.amount <= 0)) {
          setIsDepositModalOpen(true);
        } else {
          const largestBalance = balanceList.sort((a, b) => b.amount! - a.amount!)[0];
          setSelectedNativeChain(largestBalance.chain as Chain);
        }
      }
    }
  }, [isLoadingUserBalance, getUserBalance(chain, "amount")]);

  // Get Token Price Ratio
  const {
    data: priceRatioData,
    isLoading: isNativeTokenPriceLoading,
    refetch: refetchPriceRatio,
    isRefetching: isNativeTokenPriceRefetching,
  } = useQuery({
    queryKey: ["target-token-price-ratio", targetTokenConfig[selectedTargetChain]?.token],
    queryFn: () => {
      return axiosService.getNativePrice({
        tokenAddress: targetTokenConfig[selectedTargetChain]?.token!,
        chain: selectedTargetChain,
      });
    },
    enabled: !!targetTokenConfig[selectedTargetChain]?.token,
  });

  // Get Token Balance
  const {
    data: targetTokenBalanceData,
    isLoading: isTargetTokenBalanceLoading,
    refetch: refetchTargetTokenBalance,
    isRefetching: isTargetTokenBalanceRefetching,
  } = useQuery({
    queryKey: ["target-token-balance", targetTokenConfig[selectedTargetChain]?.token],
    queryFn: () => {
      if (selectedTargetChain === "ton") {
        return axiosService.getJettonBalance({
          masterAddress: targetTokenConfig[selectedTargetChain]?.token!,
          walletAddress: senderAddress,
        });
      } else {
        return axiosService.getTokenBalance({
          tokenAddress: targetTokenConfig[selectedTargetChain]?.token!,
          walletAddress: senderAddress,
          chain: selectedTargetChain,
          type: fetchBalanceTypeRef.current,
        });
      }
    },
    enabled: !!targetTokenConfig[selectedTargetChain]?.token,
  });

  const handleSetSelectedNativeChain = (chain: Chain) => {
    setSelectedNativeChain(chain);
  };

  const handleSetSelectedTargetChain = (chain: Chain) => {
    setSelectedTargetChain(chain);
  };

  const handleSetIsTrading = (isTrading: boolean) => {
    setIsTrading(isTrading);
  };

  const handleSetMode = (mode: "BUY" | "SELL" | "SWAP" | "UNWRAP") => {
    return new Promise<void>((resolve) => {
      setMode(mode);
      setSelectedNativeChain(chain);
      setSelectedTargetChain(chain);
      resolve();
    });
  };

  const getNativePriceByChain = (chain: Chain) => {
    if (priceRatioData) {
      return priceRatioData[nativePriceMap[chain]];
    } else {
      return null;
    }
  };

  return (
    <TradeContext.Provider
      value={{
        // Mode
        mode,
        // Native Token
        nativeTokenConfig,
        selectedNativeChain,
        jettonBuyRatio: priceRatioData?.jettonBuyRatio,
        jettonSellRatio: priceRatioData?.jettonSellRatio,
        nativeTokenPrice: priceRatioData?.[nativePriceMap[selectedNativeChain]] ?? null,
        nativeBalanceUsd: getUserBalance(selectedNativeChain, "usd") ?? null,
        nativeBalanceAmount: getUserBalance(selectedNativeChain, "amount") ?? null,
        isNativeTokenPriceLoading,
        isNativeTokenPriceRefetching,
        // Target Token
        targetTokenConfig,
        selectedTargetChain,
        targetTokenPrice:
          selectedTargetChain === "ton"
            ? mode === "BUY"
              ? // USD/TON * USD/JETTON
                priceRatioData?.jettonBuyRatio
              : priceRatioData?.jettonSellRatio
            : priceRatioData?.price ?? null,
        targetBalanceUsd: targetTokenBalanceData?.usdValue,
        targetBalanceAmount: targetTokenBalanceData?.quantity,
        targetBalancePerformance: targetTokenBalanceData?.performance,
        targetBalanceProfit: targetTokenBalanceData?.profit,
        isTargetTokenBalanceLoading,
        isTargetTokenBalanceRefetching,
        nativeToTargetTokenRatio:
          selectedTargetChain === "ton"
            ? mode === "BUY"
              ? getNativePriceByChain(selectedNativeChain) / priceRatioData?.jettonBuyRatio
              : getNativePriceByChain("ton") / priceRatioData?.jettonSellRatio
            : getNativePriceByChain(selectedNativeChain) / priceRatioData?.price,
        // Trade
        destinationAddress:
          mode === "BUY" || mode === "SWAP"
            ? walletAddresses?.[CHAIN_WALLET_MAP[selectedTargetChain]]
            : walletAddresses?.[CHAIN_WALLET_MAP[selectedNativeChain]],
        // Wallets
        senderAddress,
        // States
        isTrading,
        isTradeInitingRef,
        isTradeIniting,
        shouldTradeDrawerOpen,
        isDepositModalOpen,
        getNativePriceByChain,
        refetchPriceRatio,
        refetchTargetTokenBalance,
        handleSetMode,
        handleSetIsTrading,
        handleSetSelectedNativeChain,
        handleSetSelectedTargetChain,
        isPolling,
        setIsPolling,
        isTonBoardcasting,
        setIsTonBoardcasting,
      }}>
      {props.children}
    </TradeContext.Provider>
  );
}

export const useTradeContext = () => useContext(TradeContext);
