import { Box, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { InnerContentBox } from "components/ContentCard";

export const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  height: "68px",
  width: "100%",
}));

export const JettonImage = styled("img")(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.border.light}`,
  objectFit: "cover",
  objectPosition: "center",
}));

export const BondingCurveTooltipContainer = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.surface.silver,
    border: `1px solid ${theme.palette.border.dark}`,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

export const BondingCurveTooltipTrigger = styled(Box)<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  position: "relative",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: theme.spacing(0.5),
  paddingLeft: theme.spacing(0.5),
  border: `1px solid transparent`,
  cursor: "pointer",
  ...(isOpen && {
    background: theme.palette.surface.silver,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.border.dark}`,
  }),
}));

export const BondingCurveChartWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  aspectRatio: "1",
  border: `1px solid ${theme.palette.border.dark}`,
  borderRadius: theme.spacing(1),
  overflow: "hidden",
}));

export const MinimumEntryAmountWrapper = styled(InnerContentBox)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const PerformanceWrapper = styled(InnerContentBox)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  flex: 1,

  [theme.breakpoints.down("sm")]: {
    width: `calc(50% - ${theme.spacing(0.5)})`,
    flex: "unset",
  },
}));
