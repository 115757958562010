import {
  ExportKeyButtonWrapper,
  StyledCopyIcon,
  WalletDetailItemContainer,
  WalletDetailItemNetworkContainer,
  WalletDetailListBodyContainer,
  WalletDetailListContainer,
  WalletDetailListHeaderContainer,
  WidgeWrapper,
} from "./styled";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { Box, IconButton, Typography } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy-brand.svg";
import { useEffect, useState } from "react";
import { ExportPrivateKeyModal } from "./ExportPrivateKeyModal";
import { copyToClipboard, decodePrivateKeyHash } from "utils";
import { useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";

enum NetworkShortForm {
  BASE,
  SOL,
  TON,
}

const WalletTypeMaps = {
  BASE: "ethAddress",
  SOL: "solAddress",
  TON: "tonAddress",
};

const WalletDetail = () => {
  const { walletAddresses, tgUserId, hasWallet, createWallet } = useUserStoreV2();

  const [isOpennedModal, setIsOpennedModal] = useState(false);
  const [copiedWalletIndex, setCopiedWalletIndex] = useState<number | null>(null);
  const [keys, setKeys] = useState<Record<string, string | null>>({
    ethereum: null,
    solana: null,
    ton: null,
  });
  const [isAllKeysLoaded, setIsAllKeysLoaded] = useState(false);

  // Get private key hashes
  const { data: ethereumPrivateKeyData, isLoading: isLoadingEth } = useQuery({
    queryKey: ["ethPrivateKey"],
    queryFn: async () => {
      const { res } = await axiosService.getPrivateKey({
        chain: "ethereum",
      });
      return res;
    },
    enabled: !!hasWallet && !isAllKeysLoaded,
  });

  const { data: solanaPrivateKeyData, isLoading: isLoadingSol } = useQuery({
    queryKey: ["solPrivateKey"],
    queryFn: async () => {
      const { res } = await axiosService.getPrivateKey({
        chain: "solana",
      });
      return res;
    },
    enabled: !!hasWallet && !isAllKeysLoaded,
  });

  const { data: tonPrivateKeyData, isLoading: isLoadingTon } = useQuery({
    queryKey: ["tonPrivateKey"],
    queryFn: async () => {
      const { res } = await axiosService.getPrivateKey({
        chain: "ton",
      });
      return res;
    },
    enabled: !!hasWallet && !isAllKeysLoaded,
  });

  useEffect(() => {
    if (!isLoadingSol && solanaPrivateKeyData) {
      setKeys((pre) => ({
        ...pre,
        solana: decodePrivateKeyHash(
          solanaPrivateKeyData.encryptedData,
          tgUserId!,
          solanaPrivateKeyData.iv,
        ),
      }));
    }
    if (!isLoadingTon && tonPrivateKeyData) {
      setKeys((pre) => ({
        ...pre,
        ton: decodePrivateKeyHash(tonPrivateKeyData.encryptedData, tgUserId!, tonPrivateKeyData.iv),
      }));
    }
    if (!isLoadingEth && ethereumPrivateKeyData) {
      setKeys((pre) => ({
        ...pre,
        ethereum: decodePrivateKeyHash(
          ethereumPrivateKeyData.encryptedData,
          tgUserId!,
          ethereumPrivateKeyData.iv,
        ),
      }));
    }
  }, [tonPrivateKeyData, solanaPrivateKeyData, ethereumPrivateKeyData]);

  useEffect(() => {
    if (Object.keys(keys).some((key) => keys[key] === null)) {
      setIsAllKeysLoaded(false);
    } else {
      setIsAllKeysLoaded(true);
    }
  }, [keys]);

  const handleOpenModal = () => {
    setIsOpennedModal(true);
  };

  const handleCloseModal = () => {
    setIsOpennedModal(false);
  };

  return (
    <>
      <WidgeWrapper>
        <WalletDetailListHeaderContainer>
          <Typography variant="pre-title" color="text.secondary">
            WALLET ADDRESS
          </Typography>
          {hasWallet ? (
            <ExportKeyButtonWrapper>
              <RetroButton disabled={!isAllKeysLoaded} variant="white" onClick={handleOpenModal}>
                <Typography variant="button-md">
                  {!isAllKeysLoaded ? "Loading Key..." : "EXPORT KEY"}
                </Typography>
              </RetroButton>
            </ExportKeyButtonWrapper>
          ) : null}
        </WalletDetailListHeaderContainer>
        {hasWallet ? (
          <WalletDetailListBodyContainer>
            {walletAddresses &&
              [NetworkShortForm.TON, NetworkShortForm.SOL, NetworkShortForm.BASE].map(
                (network, index) => {
                  const walletType = WalletTypeMaps[
                    NetworkShortForm[network] as keyof typeof WalletTypeMaps
                  ] as "ethAddress" | "solAddress" | "tonAddress";
                  const walletAddress = walletAddresses[walletType] as string;

                  return (
                    <WalletDetailItem
                      key={network}
                      network={NetworkShortForm[network]}
                      walletAddress={walletAddress}
                      isCopied={copiedWalletIndex === index}
                      setIsCopied={() => setCopiedWalletIndex(index)}
                    />
                  );
                },
              )}
          </WalletDetailListBodyContainer>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}>
            <RetroButton onClick={createWallet}>
              <Box
                sx={{
                  padding: "12px 0px",
                  textAlign: "center",
                  width: "100%",
                }}>
                <Typography variant="title-h3">Create Wallet</Typography>
              </Box>
            </RetroButton>
          </Box>
        )}
      </WidgeWrapper>
      <ExportPrivateKeyModal keys={keys} isOpen={isOpennedModal} close={handleCloseModal} />
    </>
  );
};

const WalletDetailItem = ({
  network,
  walletAddress,
  isCopied,
  setIsCopied,
}: {
  network: string;
  walletAddress: string;
  isCopied: boolean;
  setIsCopied: () => void;
}) => {
  const handleCopyWalletAddress = () => {
    setIsCopied();
    copyToClipboard(walletAddress);
  };

  return (
    <>
      <WalletDetailItemContainer>
        <WalletDetailItemNetworkContainer>
          <Typography variant="pre-title" color="text.btn-dark">
            {network}
          </Typography>
        </WalletDetailItemNetworkContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            maxWidth: "100%",
            marginX: "12px",
            overflow: "hidden",
          }}>
          <Typography
            variant="body-md"
            sx={{
              maxWidth: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}>
            {walletAddress?.slice(0, walletAddress.length - 4)}
          </Typography>
          <Typography sx={{ whiteSpace: "nowrap" }} variant="body-md">
            {walletAddress?.slice(walletAddress.length - 4, walletAddress.length)}
          </Typography>
        </Box>
        {!isCopied ? (
          <IconButton sx={{ padding: "0px" }} onClick={handleCopyWalletAddress}>
            <CopyIcon width={16} height={16} />
          </IconButton>
        ) : (
          <Typography color="text.system-green">✔</Typography>
        )}
      </WalletDetailItemContainer>
    </>
  );
};
export { WalletDetail };
