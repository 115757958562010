import { Box, Typography, useTheme } from "@mui/material";
import { CalledByImage, TokenCallRowContainer, TrendingCalledByImage } from "./styled";
import { Brick } from "components/Brick";
import { TokenCall } from "types";
import CoinAvatar, { Chain } from "components/CoinAvatar";
import { StyledLink } from "components/CabalGroup/styled";
import { TimeToNow } from "components/TimeToNow";
import DefaultAvatarIcon from "assets/icons/default-avatar.png";
import { PercentageBrick } from "components/PercentageBrick";
import { ThumbUp } from "@mui/icons-material";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";

type TokenCallRowProps = {
  tokenCall: TokenCall;
};

const tokenCallColWidth = {
  token: {
    minWidth: {
      xs: "100px",
      "token-call-joined-sm": "140px",
    },
  },
  caller: {
    width: "80px",
  },
  confidence: {
    width: "60px",
  },
  time: {
    width: "80px",
  },
};

const TokenCallHeader = () => {
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", paddingX: "14px" }}>
      <Typography
        variant="pre-title-2"
        sx={{
          ...tokenCallColWidth.token,
        }}>
        Token
      </Typography>
      <Typography
        variant="pre-title-2"
        sx={{
          textAlign: "center",
          ...tokenCallColWidth.caller,
        }}>
        Caller
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...tokenCallColWidth.confidence,
        }}>
        <ThumbUp
          sx={{
            width: 12,
            height: 12,
          }}
          width={12}
          height={12}
        />
      </Box>
      <Typography
        variant="pre-title-2"
        sx={{
          textAlign: "center",
          ...tokenCallColWidth.time,
        }}>
        Time
      </Typography>
    </Box>
  );
};

const TokenCallRow = ({ tokenCall }: TokenCallRowProps) => {
  const { chain, iconUri, symbol, createdAt, confidenceInterval, jetton, address, groupId } =
    tokenCall;
  const theme = useTheme();
  const navigate = useNavigatePreserveQuery();

  const navigateToTokenDetailPage = () => {
    navigate({
      pathname: `/token/${address}`,
      search: `chain=${chain}${groupId ? `&groupId=${groupId}` : ""}`,
    });
  };

  return (
    <StyledLink onClick={navigateToTokenDetailPage}>
      <TokenCallRowContainer>
        <Box
          sx={{
            ...tokenCallColWidth.token,
            display: "flex",
            alignItems: "center",
          }}>
          <CoinAvatar
            style={{
              width: "24px",
              height: "24px",
            }}
            badgeStyle={{
              width: "16px",
              height: "16px",
            }}
            iconUri={iconUri}
            chain={chain as Chain}
            size="sm"
          />
          <Typography
            variant="subtitle"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginLeft: "10px",
              width: "100%",
              maxWidth: tokenCallColWidth.token,
              minWidth: tokenCallColWidth.token,
            }}>
            {symbol}
          </Typography>
        </Box>
        <Box
          sx={{
            width: tokenCallColWidth.caller,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <CalledByImage src={jetton.imageUri} alt={jetton.name} />
        </Box>
        <Box
          sx={{
            width: tokenCallColWidth.confidence,
            paddingX: theme.spacing(1),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Brick variant="dark">
            <Typography variant="body-sm" color={theme.palette.text.secondary}>
              {confidenceInterval !== undefined ? `${confidenceInterval}%` : "N/A"}
            </Typography>
          </Brick>
        </Box>
        <Box sx={{ width: "80px", textAlign: "right" }}>
          <Typography
            variant="body-sm"
            color={theme.palette.text.secondary}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              whiteSpace: "nowrap",
            }}>
            <TimeToNow time={createdAt} />
          </Typography>
        </Box>
      </TokenCallRowContainer>
    </StyledLink>
  );
};

const trendingTokenColWidth = {
  token: {
    minWidth: {
      xs: "100px",
      "token-call-joined-sm": "140px",
    },
  },
  caller: {
    width: "80px",
  },
  performance: {
    width: "120px",
  },
};

const TrendingTokenCallHeader = () => {
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", paddingX: "8px" }}>
      <Typography
        variant="pre-title-2"
        sx={{
          ...trendingTokenColWidth.token,
        }}>
        Token
      </Typography>
      <Typography
        variant="pre-title-2"
        sx={{
          textAlign: "center",
          ...trendingTokenColWidth.caller,
        }}>
        Caller
      </Typography>
      <Typography
        variant="pre-title-2"
        sx={{
          ...trendingTokenColWidth.performance,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
          marginTop: "-2px",
        }}>
        <Box>Since</Box>
        <Box
          sx={{
            marginTop: "-4px",
          }}>
          1<sup>st</sup>
        </Box>
        <Box>call</Box>
      </Typography>
    </Box>
  );
};

const TrendingTokenCallRow = ({ tokenCall }: TokenCallRowProps) => {
  const theme = useTheme();
  const navigate = useNavigatePreserveQuery();
  const {
    chain,
    iconUri,
    symbol,
    day7Performance,
    cabals,
    address,
    groupId,
    performanceSinceCalled,
  } = tokenCall;

  const handleOnError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = DefaultAvatarIcon;
  };

  const navigateToTokenDetailPage = () => {
    navigate(
      {
        pathname: `/token/${address}`,
        search: `chain=${chain}${groupId ? `&groupId=${groupId}` : ""}`,
      },
      { state: { isFromTrendingCall: true } },
    );
  };

  return (
    <StyledLink onClick={navigateToTokenDetailPage}>
      <TokenCallRowContainer>
        <Box
          sx={{
            ...trendingTokenColWidth.token,
            display: "flex",
            gap: theme.spacing(1.5),
            alignItems: "center",
          }}>
          <CoinAvatar
            style={{
              width: "24px",
              height: "24px",
            }}
            badgeStyle={{
              width: "16px",
              height: "16px",
            }}
            iconUri={iconUri}
            chain={chain as Chain}
            size="sm"
          />
          <Typography
            variant="subtitle"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>
            {symbol}
          </Typography>
        </Box>
        <Box
          sx={{
            ...trendingTokenColWidth.caller,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {cabals.slice(0, 3).map((cabal, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                transform: `translateX(-${index * 50}%)`,
              }}>
              <TrendingCalledByImage
                src={cabal.imageUri[0] ?? DefaultAvatarIcon}
                alt={cabal.groupId.toString()}
                onError={handleOnError}
              />
              {index === 2 && cabals.length > 3 && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    bottom: 0,
                    right: "-4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: theme.palette.surface.silver,
                    border: `1px solid ${theme.palette.border.dark}`,
                    borderRadius: "50%",
                    transform: "translateY(-50%)",
                    width: "24px",
                    height: "24px",
                  }}>
                  <Typography variant="body-sm" color={theme.palette.text.secondary}>
                    +{cabals.length - 3}
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...trendingTokenColWidth.performance,
          }}>
          <PercentageBrick percentage={performanceSinceCalled} />
        </Box>
      </TokenCallRowContainer>
    </StyledLink>
  );
};

export { TokenCallRow, TokenCallHeader, TrendingTokenCallRow, TrendingTokenCallHeader };
