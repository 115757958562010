import { StyledUserIcon } from "./styled";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { Box, Typography } from "@mui/material";

export const Profile = () => {
  const { tgPhotoUrl, tgUserName, logoutTgUserV2, checkIsFromMiniApp } = useUserStoreV2();
  const isFromMiniApp = checkIsFromMiniApp();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        gap: "21px",
      }}>
      <StyledUserIcon src={tgPhotoUrl} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "4px",
        }}>
        <Typography variant="title-h3">{tgUserName}</Typography>
        {!isFromMiniApp ? (
          <Typography variant="pre-title" color="text.system-red" onClick={logoutTgUserV2}>
            LOGOUT
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};
