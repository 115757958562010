import { useQuery } from "@tanstack/react-query";
import React, { createContext, useContext, useRef, useState } from "react";
import axiosService from "services/axios";
import useTradeStore from "store/trade-store.ts/useTradeStore";
import { WalletAddresses } from "store/user-store-v2";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { Chain } from "types";

export const EVM_CHAINS = ["ethereum", "base"];

const CHAIN_WALLET_MAP: Record<string, keyof WalletAddresses> = {
  ton: "tonAddress",
  solana: "solAddress",
  ethereum: "ethAddress",
  base: "ethAddress",
};

export const CHAIN_ABBREVIATION_MAP: Record<string, string> = {
  ton: "TON",
  solana: "SOL",
  ethereum: "ETH",
  base: "BASE",
};

export const nativePriceMap: Record<Chain, string> = {
  solana: "solPrice",
  ethereum: "ethPrice",
  base: "ethPrice",
  ton: "tonPrice",
};

export interface ITradeContext {
  mode: "BUY" | "SELL";
  selectedNativeChain: Chain;
  nativeTokenConfig: Record<string, TokenConfig>;
  nativeTokenPrice: number | null;
  nativeBalanceUsd: number | null;
  nativeBalanceAmount: number | null;
  selectedTargetChain: Chain;
  targetTokenConfig: Record<string, TokenConfig>;
  targetTokenPrice: number | null;
  targetBalanceUsd: number | null;
  targetBalanceAmount: number | null;
  nativeToTargetTokenRatio: number | null;
  isTradeInitingRef: React.MutableRefObject<boolean>;
  isTradeIniting: boolean;
  senderAddress: string;
  shouldTradeDrawerOpen: boolean;
  isDepositModalOpen: boolean;
  isTrading: boolean;
  tgGroupId?: number | null;
  destinationAddress?: string | null;
  getNativePriceByChain: (chain: Chain) => number | null;
  refetchPriceRatio: () => void;
  refetchTargetTokenBalance: () => void;
  handleSetMode: (mode: "BUY" | "SELL") => void;
  handleModalOpen: () => void;
  handleSetIsTrading: (isTrading: boolean) => void;
  handleSetSelectedNativeChain: (chain: Chain) => void;
  handleSetSelectedTargetChain: (chain: Chain) => void;
}

export const TradeContext = createContext<ITradeContext>({
  mode: "BUY",
  selectedNativeChain: "ton",
  nativeTokenConfig: {},
  nativeTokenPrice: null,
  nativeBalanceUsd: null,
  nativeBalanceAmount: null,
  selectedTargetChain: "ethereum",
  targetTokenConfig: {},
  targetTokenPrice: null,
  targetBalanceUsd: null,
  targetBalanceAmount: null,
  nativeToTargetTokenRatio: null,
  senderAddress: "",
  isTradeInitingRef: { current: false },
  isTradeIniting: false,
  shouldTradeDrawerOpen: false,
  isDepositModalOpen: false,
  isTrading: false,
  tgGroupId: null,
  destinationAddress: null,
  getNativePriceByChain: () => null,
  refetchPriceRatio: () => {},
  refetchTargetTokenBalance: () => {},
  handleSetMode: () => {},
  handleModalOpen: () => {},
  handleSetIsTrading: () => {},
  handleSetSelectedNativeChain: () => {},
  handleSetSelectedTargetChain: () => {},
});

export interface ITradeConfig {
  destToken: string;
  srcToken: string;
  destChains: string[];
  nativeChains: string[];
}

export interface TokenConfig {
  chain: Chain;
  symbol: string;
  token?: string;
  imageUrl?: string;
}

export interface ITradeProps {
  chain: Chain;
  nativeTokenConfig: Record<string, TokenConfig>;
  targetTokenConfig: Record<string, TokenConfig>;
  children: React.ReactNode;
}

export function TradeProvider(props: ITradeProps) {
  // Data
  const [mode, setMode] = useState<"BUY" | "SELL">("BUY");
  const { chain, nativeTokenConfig, targetTokenConfig } = props;

  // Chains List
  const nativeChains = Object.keys(nativeTokenConfig);
  const targetChains = Object.keys(targetTokenConfig);

  // Current chain
  const [selectedNativeChain, setSelectedNativeChain] = useState<Chain>(chain);
  const [selectedTargetChain, setSelectedTargetChain] = useState<Chain>(chain);

  // User
  const { walletAddresses, createWallet, hasWallet } = useUserStoreV2();

  // Flags
  const [isTrading, setIsTrading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const isTradeInitingRef = useRef(false);
  const [isTradeIniting, setIsTradeIniting] = useState(false);

  // Deposit Modal
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);

  // Trade Drawer
  const [shouldTradeDrawerOpen, setShouldTradeDrawerOpen] = useState(false);

  const fetchBalanceTypeRef = useRef<"realTime" | "archive">("archive");

  // Balance
  const { getUserBalance, updateUserBalance } = useTradeStore();

  // Sender Address
  const senderAddress =
    walletAddresses?.[
      CHAIN_WALLET_MAP[(mode === "BUY" ? selectedNativeChain : selectedTargetChain) as Chain]
    ] ?? "";

  // Get Token Price Ratio
  const {
    data: priceRatioData,
    isLoading: isPriceRatioLoading,
    refetch: refetchPriceRatio,
    isRefetching: isPriceRatioRefetching,
  } = useQuery({
    queryKey: ["target-token-price-ratio", targetTokenConfig[selectedTargetChain].token],
    queryFn: () => {
      return axiosService.getNativePrice({
        tokenAddress: targetTokenConfig[selectedTargetChain].token!,
        chain: selectedTargetChain,
      });
    },
    enabled: !!targetTokenConfig[selectedTargetChain].token,
  });

  // Get Token Balance
  const {
    data: targetTokenBalanceData,
    isLoading: isTargetTokenBalanceLoading,
    refetch: refetchTargetTokenBalance,
    isRefetching: isTargetTokenBalanceRefetching,
  } = useQuery({
    queryKey: ["target-token-balance", targetTokenConfig[selectedTargetChain].token],
    queryFn: () => {
      if (selectedTargetChain === "ton") {
        return axiosService.getJettonBalance({
          masterAddress: targetTokenConfig[selectedTargetChain].token!,
          walletAddress: senderAddress,
        });
      } else {
        return axiosService.getTokenBalance({
          tokenAddress: targetTokenConfig[selectedTargetChain].token!,
          walletAddress: senderAddress,
          chain: selectedTargetChain,
          type: fetchBalanceTypeRef.current,
        });
      }
    },
    enabled: !!targetTokenConfig[selectedTargetChain].token,
  });

  const handleSetSelectedNativeChain = (chain: Chain) => {
    setSelectedNativeChain(chain);
  };

  const handleSetSelectedTargetChain = (chain: Chain) => {
    setSelectedTargetChain(chain);
  };

  const handleSetIsTrading = (isTrading: boolean) => {
    setIsTrading(isTrading);
  };

  const handleSetMode = (mode: "BUY" | "SELL") => {
    setMode(mode);
    setSelectedNativeChain(chain);
    setSelectedTargetChain(chain);
  };

  // Open Trade Modal
  const handleModalOpen = async () => {
    try {
      // De-Bounce
      if (isTradeInitingRef.current || isTradeIniting) {
        return;
      }

      // Init Flags
      isTradeInitingRef.current = true;
      setIsTradeIniting(true);

      // Check Wallet balance
      if (getUserBalance(chain, "usd") && getUserBalance(chain, "usd")! <= 0) {
        // If no wallet, create wallets
        if (!hasWallet) {
          await createWallet();
        }

        // Open Deposit Modal
        setIsDepositModalOpen(true);
      } else {
        refetchPriceRatio();
        setShouldTradeDrawerOpen(true);
        await updateUserBalance();
      }
    } catch (error) {
      console.error("Error while initing trade drawer", error);
    } finally {
      setIsTradeIniting(false);
      isTradeInitingRef.current = false;
    }
  };

  const getNativePriceByChain = (chain: Chain) => {
    if (priceRatioData) {
      return priceRatioData[nativePriceMap[chain]];
    } else {
      return null;
    }
  };

  return (
    <TradeContext.Provider
      value={{
        // Mode
        mode,
        // Native Token
        nativeTokenConfig,
        selectedNativeChain,
        nativeTokenPrice: priceRatioData?.[nativePriceMap[selectedNativeChain]] ?? null,
        nativeBalanceUsd: getUserBalance(selectedNativeChain, "usd") ?? null,
        nativeBalanceAmount: getUserBalance(selectedNativeChain, "amount") ?? null,
        // Target Token
        targetTokenConfig,
        selectedTargetChain,
        targetTokenPrice:
          selectedTargetChain === "ton"
            ? mode === "BUY"
              ? // USD/TON * USD/JETTON
                priceRatioData?.jettonBuyRatio
              : priceRatioData?.jettonSellRatio
            : priceRatioData?.price ?? null,
        targetBalanceUsd: targetTokenBalanceData?.usdValue,
        targetBalanceAmount: targetTokenBalanceData?.quantity,
        nativeToTargetTokenRatio:
          selectedTargetChain === "ton"
            ? mode === "BUY"
              ? getNativePriceByChain(selectedNativeChain) / priceRatioData?.jettonBuyRatio
              : getNativePriceByChain("ton") / priceRatioData?.jettonSellRatio
            : getNativePriceByChain(selectedNativeChain) / priceRatioData?.price,
        // Trade
        destinationAddress:
          mode === "BUY"
            ? CHAIN_WALLET_MAP[selectedTargetChain]
            : CHAIN_WALLET_MAP[selectedNativeChain],
        // Wallets
        senderAddress,
        // States
        isTrading,
        isTradeInitingRef,
        isTradeIniting,
        shouldTradeDrawerOpen,
        isDepositModalOpen,
        getNativePriceByChain,
        refetchPriceRatio,
        refetchTargetTokenBalance,
        handleSetMode,
        handleModalOpen,
        handleSetIsTrading,
        handleSetSelectedNativeChain,
        handleSetSelectedTargetChain,
      }}>
      {props.children}
    </TradeContext.Provider>
  );
}

export const useTradeContext = () => useContext(TradeContext);
