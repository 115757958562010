import { Box, styled } from "@mui/material";
import { Drawer } from "@mui/material";

export const StyledDepositDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "100%",
    height: "calc(100% - 31px)",
    backgroundColor: theme.palette.surface.bg,
    display: "flex",
    flexdirection: "column",
    alignItems: "center",
    padding: "20px",
    gap: "48px",

    [theme.breakpoints.down("sm")]: {
      gap: "24px",
    },
  },
}));

export const DepositDetailWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
}));

export const WalletAddressContainer = styled("div")(({ theme }) => ({
  maxWidth: "100%",
  borderRadius: "16px",
  backgroundColor: theme.palette.surface.silver,
  border: `1px solid ${theme.palette.border.dark}`,
  padding: "12px 16px",
  textAlign: "center",
  overflowWrap: "break-word",
  marginBottom: "24px",
}));

export const DepositTextWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "343px",
  textAlign: "center",
  marginBottom: "48px",

  [theme.breakpoints.down("sm")]: {
    marginBottom: "24px",
  },
}));
