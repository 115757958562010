import { Box, DrawerProps, styled, Typography, useTheme } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { useState } from "react";
import { buttonConfig, DarkDrawer, NumpadKey } from "components/TradeDrawer";
import RetroModal from "components/RetroModal";
import { MIN_ENTRY_AMOUNT, MIN_ENTRY_USD } from "consts";
import { abbreviateNumber, toFixedIfNecessary } from "utils";
import { useTradeDrawer } from "hooks/useTradeDrawer";
import { EVM_CHAINS, useTradeContext } from "contexts/TradeContext";
import { BondingCurveType, Chain } from "types";
import { CircleButton } from "components/CircleButton";
import { Close } from "@mui/icons-material";
import { StyledSelect } from "./styled";
import { MenuItem } from "@mui/material";
import { CHAIN_BADGE_MAP } from "components/CoinAvatar";
import { SmallNumberView } from "components/SmallValueView/SmallValueView";
import { ReactComponent as XSquare } from "assets/icons/xsquare.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import useTradeStore from "store/trade-store.ts/useTradeStore";

export const CHAIN_ABBREVIATION_MAP: Record<string, string> = {
  ton: "TON",
  solana: "SOL",
  ethereum: "ETH",
  base: "BASE",
};

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  useGrouping: false,
});

interface JettonTradeDrawerProps {
  tgGroupId: number;
  minEntryPrice: number;
  bondingCurveType: BondingCurveType;
  onProcessing?: () => void;
  onProcessed?: () => void;
  onConfirm?: (message?: string) => void;
  onFail?: (message?: string) => void;
  onClose: () => void;
}

export const JettonTradeDrawer = (props: DrawerProps & JettonTradeDrawerProps) => {
  const MIN_BUY_AMOUNT = props.bondingCurveType === "casual" ? props.minEntryPrice : MIN_ENTRY_USD;

  const {
    mode,
    nativeTokenPrice,
    nativeTokenConfig,
    targetTokenConfig,
    selectedNativeChain,
    selectedTargetChain,
    targetTokenPrice,
    targetBalanceAmount,
    nativeToTargetTokenRatio,
    handleSetMode,
    handleSetSelectedNativeChain,
  } = useTradeContext();

  const {
    inputUsd,
    targetAmount,
    nativeAmount,
    shouldConfirmDisabled,
    isOpenConfirmDrawer,
    onConfirm,
    onClose,
    onNumpadPress,
    onPercentPress,
    handleOpenConfirmDrawer,
    priceHint,
  } = useTradeDrawer({
    defaultInputUsd: MIN_BUY_AMOUNT,
    tgGroupId: props.tgGroupId,
    onConfirm: props.onConfirm,
    onProcessing: props.onProcessing,
    onProcessed: props.onProcessed,
    onClose: () => {
      handleSetMode("BUY");
      props.onClose();
    },
    onFail: props.onFail,
  });

  const { getUserBalance } = useTradeStore();

  // Reminder Drawer
  const [shouldShowAmountTooSmall, setShouldShowAmountTooSmall] = useState(false);
  const [shouldShowConfirmToLeave, setShouldShowConfirmToLeave] = useState(false);

  // Remindar Modal
  const [modalConfig, setModalConfig] = useState<{
    variant: "success" | "error";
    title: string;
    message: string;
  } | null>(null);

  const theme = useTheme();

  const handleConfirmToLeave = () => {
    setShouldShowConfirmToLeave(false);
    onConfirm?.();
  };

  const cancelConfirmToLeave = () => {
    setShouldShowConfirmToLeave(false);
  };

  // Start Trading
  const handleConfirm = () => {
    if (mode === "BUY" && +inputUsd < MIN_BUY_AMOUNT) {
      setShouldShowAmountTooSmall(true);
      return;
    }

    if (mode === "SELL" && targetBalanceAmount! - targetAmount < MIN_ENTRY_AMOUNT) {
      setShouldShowConfirmToLeave(true);
      return;
    }

    handleOpenConfirmDrawer(true);
  };

  return (
    <>
      <DarkDrawer anchor="bottom" sx={{ zIndex: 100 }} open={props.open}>
        {/* Tool Bar */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {/* Slippage Button */}
          <Box sx={{ width: "54px" }} />
          {/* Buy / Sell */}
          <Box
            sx={{
              background: theme.palette.surface.container,
              borderRadius: theme.spacing(2),
              border: `1px solid ${theme.palette.border.dark}`,
              padding: theme.spacing(1),
              display: "flex",
              gap: theme.spacing(1),
            }}>
            <RetroButton onClick={() => handleSetMode("SELL")} disabled={mode === "SELL"}>
              <Box sx={{ padding: theme.spacing(1.5, 3) }}>
                <Typography variant="body-md">Sell</Typography>
              </Box>
            </RetroButton>
            <RetroButton onClick={() => handleSetMode("BUY")} disabled={mode === "BUY"}>
              <Box sx={{ padding: theme.spacing(1.5, 3) }}>
                <Typography variant="body-md">Buy</Typography>
              </Box>
            </RetroButton>
          </Box>
          {/* Close Button */}
          <Box>
            <CircleButton variant="white" size="lg" onClick={onClose}>
              <Close />
            </CircleButton>
          </Box>
        </Box>
        {/* Trade detail */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "32px",
            gap: "8px",
          }}>
          {/* Source Token Select (balance USD) */}
          <Box sx={{ flex: 1, display: "flex", justifyContent: "end", alignItems: "center" }}>
            <Box sx={{ width: "158px" }}>
              <StyledSelect
                defaultValue={
                  mode === "BUY"
                    ? selectedNativeChain
                    : targetTokenConfig[selectedTargetChain].symbol
                }
                noPointer={mode === "SELL"}
                value={
                  mode === "BUY"
                    ? selectedNativeChain
                    : targetTokenConfig[selectedTargetChain].symbol
                }
                sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
                MenuProps={{
                  slotProps: {
                    paper: {
                      sx: {
                        borderRadius: theme.spacing(1.5),
                        backgroundColor: theme.palette.surface.silver,
                      },
                    },
                  },
                }}
                onChange={(event) => handleSetSelectedNativeChain(event.target.value as Chain)}
                IconComponent={mode === "SELL" ? () => null : undefined}>
                {/* Default Option */}
                {mode === "BUY" ? (
                  Object.keys(CHAIN_ABBREVIATION_MAP).map((chain) => (
                    <MenuItem
                      selected={selectedNativeChain === chain}
                      value={chain}
                      sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                      <img
                        src={CHAIN_BADGE_MAP[chain as Chain]}
                        alt={CHAIN_ABBREVIATION_MAP[chain as Chain]}
                        width={16}
                        height={16}
                      />
                      <Typography
                        sx={{
                          width: "100%",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          gap: theme.spacing(1),
                        }}
                        variant="body-default"
                        color={theme.palette.text.secondary}>
                        <SmallNumberView
                          value={getUserBalance(chain, "amount")!}
                          variants="body-default"
                        />{" "}
                        {EVM_CHAINS.includes(chain as Chain)
                          ? CHAIN_ABBREVIATION_MAP["ethereum"]
                          : CHAIN_ABBREVIATION_MAP[chain as Chain]}
                      </Typography>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem
                    selected={true}
                    value={targetTokenConfig[selectedTargetChain].symbol}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}>
                    <img
                      src={targetTokenConfig[selectedTargetChain].imageUrl}
                      alt={targetTokenConfig[selectedTargetChain].symbol}
                      width={16}
                      height={16}
                      style={{ borderRadius: "50%" }}
                    />
                    <Typography
                      sx={{ width: "100%", textAlign: "center" }}
                      variant="body-default"
                      color={theme.palette.text.secondary}>
                      <SmallNumberView value={targetBalanceAmount!} variants="body-default" />{" "}
                      {targetTokenConfig[selectedTargetChain].symbol}
                    </Typography>
                  </MenuItem>
                )}
              </StyledSelect>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ArrowRight width={24} />
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "start", alignItems: "center" }}>
            <Box sx={{ width: "158px" }}>
              <StyledSelect
                defaultValue={
                  mode === "SELL"
                    ? selectedNativeChain
                    : targetTokenConfig[selectedTargetChain].symbol
                }
                noPointer={true}
                value={
                  mode === "SELL"
                    ? selectedNativeChain
                    : targetTokenConfig[selectedTargetChain].symbol
                }
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: theme.spacing(1),
                }}
                MenuProps={{
                  slotProps: {
                    paper: {
                      sx: {
                        borderRadius: theme.spacing(1.5),
                        backgroundColor: theme.palette.surface.silver,
                      },
                    },
                  },
                }}
                onChange={(event) => handleSetSelectedNativeChain(event.target.value as Chain)}
                IconComponent={() => null}>
                {/* Default Option */}
                {mode === "SELL" ? (
                  ["ton"].map((chain) => (
                    <MenuItem
                      selected={selectedNativeChain === chain}
                      value={chain}
                      sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                      <img
                        src={CHAIN_BADGE_MAP[chain as Chain]}
                        alt={CHAIN_ABBREVIATION_MAP[chain as Chain]}
                        width={16}
                        height={16}
                      />
                      <Typography
                        sx={{ width: "100%", textAlign: "center" }}
                        variant="body-default"
                        color={theme.palette.text.secondary}>
                        {EVM_CHAINS.includes(chain as Chain)
                          ? CHAIN_ABBREVIATION_MAP["ethereum"]
                          : CHAIN_ABBREVIATION_MAP[chain as Chain]}
                      </Typography>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem
                    selected={true}
                    value={targetTokenConfig[selectedTargetChain].symbol}
                    sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                    <img
                      src={targetTokenConfig[selectedTargetChain].imageUrl}
                      alt={targetTokenConfig[selectedTargetChain].symbol}
                      width={16}
                      height={16}
                      style={{ borderRadius: "50%" }}
                    />
                    <Typography
                      sx={{ width: "100%", textAlign: "center" }}
                      variant="body-default"
                      color={theme.palette.text.secondary}>
                      {targetTokenConfig[selectedTargetChain].symbol}
                    </Typography>
                  </MenuItem>
                )}
              </StyledSelect>
            </Box>
          </Box>
        </Box>

        {/* Price Display */}
        <Box sx={{ marginTop: theme.spacing(1), marginX: "auto", textAlign: "center" }}>
          {/* Entered Value (USD) */}
          <Box>
            <Typography variant="display-md">{numberFormatter.format(+inputUsd)}</Typography>
          </Box>
          {/* Target Token (Amount) Received Preview */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography
              variant="body-md"
              color={theme.palette.text.secondary}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
              ≈{" "}
              <SmallNumberView
                value={mode === "BUY" ? targetAmount : nativeAmount}
                variants="body-md"
              />
              {mode === "BUY" ? (
                <img
                  src={targetTokenConfig[selectedTargetChain].imageUrl}
                  alt={targetTokenConfig[selectedTargetChain].symbol}
                  width={16}
                  height={16}
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={CHAIN_BADGE_MAP[selectedNativeChain as Chain]}
                  alt={nativeTokenConfig[selectedNativeChain].symbol}
                  width={16}
                  height={16}
                  style={{ margin: "auto" }}
                />
              )}{" "}
              {/* ({numberFormatter.format(price)} USD) */}
            </Typography>
          </Box>
        </Box>
        {/* Remindar */}
        {priceHint}

        {/* Percentage */}
        <Box
          display={"flex"}
          gap={theme.spacing(1)}
          marginTop={theme.spacing(1)}
          width={"fit-content"}
          marginX={"auto"}>
          {buttonConfig.map((item) => (
            <RetroButton
              size="sm"
              key={item.label}
              onClick={() => {
                onPercentPress(item.value);
              }}>
              <Box sx={{ padding: theme.spacing(0.0, 2) }}>
                <Typography variant="body-default">{item.label}</Typography>
              </Box>
            </RetroButton>
          ))}
        </Box>

        {/* Numpad */}
        <Box
          marginTop={theme.spacing(1)}
          display={"flex"}
          flexWrap={"wrap"}
          width={"100%"}
          marginX={"auto"}
          maxWidth={"600px"}>
          {Array.from({ length: 9 }).map((_, index) => (
            <NumpadButton key={index} onClick={() => onNumpadPress(`${index + 1}` as NumpadKey)}>
              <Typography variant="title-h1">{index + 1}</Typography>
            </NumpadButton>
          ))}
          <NumpadButton onClick={() => onNumpadPress(".")}>
            <Typography variant="title-h1">.</Typography>
          </NumpadButton>
          <NumpadButton onClick={() => onNumpadPress("0")}>
            <Typography variant="title-h1">0</Typography>
          </NumpadButton>
          <NumpadButton
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            onClick={() => onNumpadPress("delete")}>
            <XSquare />
          </NumpadButton>
        </Box>

        {/* Confirm Button */}
        <RetroButton onClick={() => handleConfirm()} disabled={shouldConfirmDisabled}>
          <Box sx={{ padding: theme.spacing(1.5, 3) }}>
            <Typography variant="title-h3">CONFIRM</Typography>
          </Box>
        </RetroButton>
      </DarkDrawer>

      {/* Amount too small drawer */}
      <DarkDrawer
        open={shouldShowAmountTooSmall}
        anchor="bottom"
        onClose={() => {}}
        sx={{ zIndex: 200 }}>
        <Box>
          <Typography variant="title-h2">Amount is too small</Typography>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <Typography variant="body-md" color={theme.palette.text.secondary}>
            The minimum amount per deposit is ${MIN_BUY_AMOUNT}
          </Typography>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <RetroButton onClick={() => setShouldShowAmountTooSmall(false)}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              OK
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>

      {/* Confirm to leave modal */}
      <DarkDrawer
        open={shouldShowConfirmToLeave}
        anchor="bottom"
        onClose={cancelConfirmToLeave}
        sx={{ zIndex: 200 }}>
        <Box>
          <Typography variant="title-h2">Are you sure to leave?</Typography>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <Typography variant="body-md" color={theme.palette.text.secondary}>
            If you keep less than {abbreviateNumber(MIN_ENTRY_AMOUNT)} balance in your wallet you
            will be removed from the group.
          </Typography>
        </Box>

        <Box marginTop={theme.spacing(1)} display="flex" gap={theme.spacing(1)}>
          <RetroButton onClick={cancelConfirmToLeave}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              Go Back
            </Typography>
          </RetroButton>
          <RetroButton onClick={handleConfirmToLeave} variant="white">
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              Continue
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>

      {/* Confirm drawer */}
      <DarkDrawer
        open={isOpenConfirmDrawer}
        anchor="bottom"
        onClose={() => {}}
        sx={{ zIndex: 200 }}>
        <Box>
          <Typography variant="title-h2">CONFIRM</Typography>
        </Box>
        <Box
          sx={{
            marginTop: "16px",
            marginBottom: "32px",
          }}>
          <Typography variant="body-md" color={theme.palette.text.secondary}>
            You are {mode === "BUY" ? "purchasing" : "selling"} $
            {targetTokenConfig[selectedTargetChain].symbol} tokens with a total value of $
            {toFixedIfNecessary(inputUsd.toString(), 2)} ({targetAmount} $
            {targetTokenConfig[selectedTargetChain].symbol} @$
            {targetTokenPrice})
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}>
          <RetroButton variant="white" onClick={() => handleOpenConfirmDrawer(false)}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              NO
            </Typography>
          </RetroButton>
          <RetroButton onClick={() => onConfirm()}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              YES
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>

      {/* Modal */}
      <RetroModal
        open={modalConfig !== null}
        onClose={() => {
          setModalConfig(null);
        }}
        variant={modalConfig?.variant ?? "success"}
        title={modalConfig?.title ?? ""}
        message={modalConfig?.message ?? ""}
      />
    </>
  );
};

const NumpadButton = styled("button")(({ theme }) => ({
  all: "unset",
  width: "33%",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  textAlign: "center",

  background: "transparent",
  border: "none",
  cursor: "pointer",
}));
