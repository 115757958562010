import { useEffect, useState } from "react";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { decryptopnStartParams } from "utils";
import { ROUTES } from "consts";

export const useConsumeStartParams = () => {
  const [isConsumed, setIsConsumed] = useState(false);
  const navigate = useNavigatePreserveQuery();

  useEffect(() => {
    console.debug(window?.Telegram?.WebApp?.initDataUnsafe?.start_param);
    if (!isConsumed && window?.Telegram?.WebApp?.initDataUnsafe?.start_param) {
      const decodedParams = decryptopnStartParams(
        window?.Telegram?.WebApp?.initDataUnsafe?.start_param,
      );
      const url = decodedParams?.split("&").reduce((acc, cur) => {
        const [key, value] = cur.split("=");
        acc = acc.replace(`{${key}}`, value);
        return acc;
      }, "{tokenAddress}?chain={chain}&groupId={groupId}");

      const [pathname, search] = (url as string).split("?");
      setIsConsumed(true);

      delete window?.Telegram?.WebApp?.initDataUnsafe?.start_param;

      navigate({
        pathname: ROUTES.tokenId.replace(":id", pathname),
        search: search,
      });
    }
  }, []);
};
