import { Avatar, Box, IconButton, styled } from "@mui/material";

export const PortfolioContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
}));

export const WalletDetailListContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "16px",
  backgroundColor: theme.palette.surface.silver,
  borderRadius: "16px",
}));

export const WalletDetailListHeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "46px",
}));

export const WalletDetailListBodyContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  gap: "6px",
}));

export const WalletDetailItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "46px",
  padding: "0 16px",
  border: `1px solid ${theme.palette.border.dark}`,
  backgroundColor: theme.palette.surface.container,
  borderRadius: "16px",
}));

export const WalletDetailItemNetworkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.surface.light,
  padding: "6px 8px 2px",
  borderBottom: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "8px",
}));

export const TokenNetworkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.surface.light,
  padding: "6px 8px 2px",
  borderBottom: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "8px",
}));

export const WidgeWrapper = styled(Box)<{
  $justify?: "start" | "center";
  $align?: "start" | "center";
}>(({ theme, $justify = "start", $align = "start" }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: $justify,
  alignItems: $align,
  padding: "1rem",
  backgroundColor: theme.palette.surface.silver,
  border: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "16px",
  gap: "12px",
}));

export const ExportKeyButtonWrapper = styled(Box)(({ theme }) => ({
  width: "125px",
  height: "46px",
}));

export const StyledCopyIcon = styled("img")(({ theme }) => ({
  width: "16px",
  height: "16px",
}));
// export const UserWalletDetailContainer = styled(Box)(({ theme }) => ({
//   width: "100%",
//   height: "382px"
// }));

export const StyledUserIcon = styled(Avatar)(({ theme }) => ({
  width: "55px",
  height: "55px",
  borderRadius: "50%",
  border: `1px solid ${theme.palette.border.dark}`,
}));

export const TapsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  gap: "8px",
  padding: "8px",
  backgroundColor: theme.palette.surface.container,
  borderRadius: "16px",
  border: `1px solid ${theme.palette.border.dark}`,
}));

export const PortfolioDetailListContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  width: "100%",
}));

export const PortfolioDetailListHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

export const PortfolioDetailListTitle = styled(Box)(({ theme }) => ({
  width: "80px",
  marginLeft: "20px",
}));

export const PortfolioDetailListItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  backgroundColor: theme.palette.surface.silver,
  border: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "16px",
  padding: "12px",
}));

export const PortfolioDetailListItemColumnContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "86px",
  justifyContent: "start",
  alignItems: "center",
  gap: "2px",
  overflow: "hidden",
}));

export const PortfolioDetailListItemCabalIcon = styled("img")(({ theme }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "8px",
  overflow: "hidden",
}));

export const Overlay = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 999,
}));

export const ModalWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "411px",
  padding: "20px",
}));

export const CloseButtonWrapper = styled(Box)(({ theme }) => ({
  width: "52px",
  height: "52px",
  borderRadius: "50%",
}));

export const CheckCircleImage = styled("img")(({ theme }) => ({
  width: "101px",
  height: "101px",
  margin: "0 auto",
}));

export const PasswordInput = styled("input")(({ theme }) => ({
  width: "100%",
  height: "48px",
  padding: "12px 16px",
  border: `1px solid ${theme.palette.border.dark}`,
  backgroundColor: theme.palette.surface.silver,
  borderRadius: "16px",
  color: theme.palette.text.primary,
  paddingRight: "68px",
  ...theme.typography["body-md"],
}));

export const PasswordButtonGroups = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "8px",
  top: "50%",
  transform: "translateY(-50%)",
  display: "flex",
  gap: "8px",
  padding: "8px",
}));

export const ViewPasswordButton = styled(IconButton)<{ $variant: "password" | "text" }>(
  ({ theme, $variant }) => ({
    opacity: $variant === "password" ? 0.7 : 1,
    padding: "0",
  }),
);
