import { formatDistanceToNowStrict } from "date-fns";
import { useState } from "react";
import { useInterval } from "react-use";

const map: Record<string, string> = {
  " year": "y",
  " years": "y",
  " month": "mo",
  " months": "mo",
  " day": "d",
  " days": "d",
  " hour": "h",
  " hours": "h",
  " minute": "m",
  " minutes": "m",
  " second": "s",
  " seconds": "s",
};

const regex = / (years?|months?|days?|hours?|minutes?|seconds?)/g;

const getFormattedTime = (time: number | string) => {
  return formatDistanceToNowStrict(typeof time === "string" ? time : time * 1000, {
    addSuffix: true,
  }).replace(regex, (match) => {
    return map[match];
  });
};

const TimeToNow = ({ time }: { time: number | string }) => {
  const [formattedTime, setFormattedTime] = useState(getFormattedTime(time));

  useInterval(() => {
    setFormattedTime(getFormattedTime(time));
  }, 1000);

  return <>{formattedTime}</>;
};

export { TimeToNow };
