import { Box, Drawer, Slider, styled } from "@mui/material";

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.surface.bg,
    color: theme.palette.text.primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 1rem 1rem",
  },
}));

export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  height: "68px",
  width: "100%",
}));

export const WidgeWrapper = styled(Box)<{
  $justify?: "start" | "center";
  $align?: "start" | "center";
}>(({ theme, $justify = "start", $align = "start" }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: $justify,
  alignItems: $align,
  padding: "1rem",
  backgroundColor: theme.palette.surface.silver,
  border: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "16px",
}));

export const EstimatePriceWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: "12px",
  backgroundColor: theme.palette.surface.container,
  border: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "16px",
  gap: "12px",
}));

export const ChartWrapper = styled(Box)(({ theme }) => ({
  maxWidth: "100%",
  minWidth: "100%",
  minHeight: "150px",
  backgroundColor: theme.palette.surface.silver,
  border: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "16px",
  overflow: "hidden",
  pointerEvents: "none",
}));

export const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.brand.primary,
  width: "calc(100% - 12px)",
  "& .MuiSlider-mark": {
    width: "12px",
    height: "12px",
    border: `1px solid ${theme.palette.border.dark}`,
    borderRadius: "50%",
    backgroundColor: theme.palette.brand.primary,
    margin: "0 -6px",
  },
  "& .MuiSlider-thumb": {
    border: `2px solid ${theme.palette.border.dark}`,
    backgroundColor: theme.palette.surface.light,
  },
}));

export const CurveSteepnessButtonGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "11px",
  height: "fit",
  width: "fit",
  backgroundColor: theme.palette.surface.container,
  border: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "16px",
  marginBottom: "20px",
}));
