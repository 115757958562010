import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { getAccessToken, removeTokens, tgAuth } from "services/authV2";
import { userStateAtomV2 } from ".";
import useToastStore from "store/toast-store/useToastStore";
import axiosService from "services/axios";

//TODO: Dev callback url
const DEV_CALLBACK_URL = process.env.REACT_APP_DEV_CALLBACK_URL;
const LOGIN_REDIRECT_URL = process.env.REACT_APP_TG_WIDGET_LOGIN_URL;

export const DEFAULT_CACHED_SLIPPAGE = 3;

function useUserStoreV2() {
  const [state, setState] = useRecoilState(userStateAtomV2);
  const [hasWallet, setHasWallet] = useState(
    state?.walletAddresses ? Object.values(state.walletAddresses).every((wallet) => wallet) : false,
  );
  const [skipOnboarding, setSkipOnboarding] = useState(!!localStorage.getItem("isOnboarded"));
  const isLogoutingRef = useRef(false);
  const { showToast } = useToastStore();

  useEffect(() => {
    setHasWallet(
      state?.walletAddresses
        ? Object.values(state.walletAddresses).every((wallet) => wallet)
        : false,
    );
  }, [state]);

  useEffect(() => {
    const isLogined =
      state.userId &&
      state.tgUserId &&
      state.tgUserName &&
      state.walletAddresses?.tonAddress &&
      state.walletAddresses?.solAddress &&
      state.walletAddresses?.ethAddress;

    if (!localStorage.getItem("refreshToken") && isLogined) {
      logoutTgUserV2();
    }
  }, [localStorage.getItem("refreshToken")]);

  const logoutTgUserV2 = useCallback(() => {
    if (!isLogoutingRef.current) {
      isLogoutingRef.current = true;
      removeTokens();
      setState({
        userId: undefined,
        walletAddresses: {
          tonAddress: undefined,
          solAddress: undefined,
          ethAddress: undefined,
        },
        tgUserId: undefined,
        tgUserName: undefined,
        tgPhotoUrl: undefined,
        preferences: {
          slippage: undefined,
        },
      });
      window.history.replaceState({}, document.title, window.location.pathname);
      window.location.href = window.location.href.split("?")[0];
      isLogoutingRef.current = false;
    }
  }, [setState]);

  const getTgUserIdV2 = useCallback(
    async (isTryLogin: boolean = true) => {
      try {
        const isFromMiniApp = checkIsFromMiniApp();
        const isRedirectedFromCallback = _checkIsRedirectedFromCallback();
        // const accessToken = getAccessToken();

        // On Mount (TG app)
        if (!isRedirectedFromCallback && isFromMiniApp) {
          // (1): Try to get tgUserId from window app
          await _getTgUserIdByWindowApp(isTryLogin);
        }

        // On Mount (Web)
        if (isRedirectedFromCallback && !isTryLogin && !isFromMiniApp) {
          await _getTgUserIdFromCallback(isTryLogin, isRedirectedFromCallback);
        }

        // Handle Active login (TG app)
        // if (!isRedirectedFromCallback && isTryLogin && isFromMiniApp) {
        //   await _getTgUserIdByWindowApp(isTryLogin);
        // }

        // Handle Active login (Web)
        if (!isRedirectedFromCallback && isTryLogin && !isFromMiniApp) {
          await _getTgUserIdFromCallback(isTryLogin, isRedirectedFromCallback);
        }
      } catch (error) {
        console.error("Failed to Login: ", error);

        showToast({
          variant: "error",
          message: (error as Error).message,
          duration: 5000,
        });
        throw error;
      }
    },
    [setState],
  );

  const _getTgUserIdByWindowApp = useCallback(
    async (isTryLogin: boolean = true) => {
      if (window.Telegram.WebApp) {
        // Initialize the Web App
        window.Telegram.WebApp.ready();
        console.debug(
          "window.Telegram.WebApp.initDataUnsafe: ",
          window.Telegram.WebApp.initDataUnsafe,
        );

        // Get user information
        const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
        const user = initDataUnsafe.user;
        const userId = user?.id;

        if (userId) {
          const { user } = await tgAuth({
            userData: {
              ...initDataUnsafe,
            },
          });

          const {
            id,
            tgUserId,
            tgUserName,
            tonAddress,
            solAddress,
            ethAddress,
            tgPhotoUrl,
            preferences,
          } = user;

          setState((prevState) => {
            return {
              ...prevState,
              userId: id,
              walletAddresses: {
                tonAddress,
                solAddress,
                ethAddress,
              },
              tgUserId,
              tgUserName,
              tgPhotoUrl,
              preferences: {
                ...preferences,
                slippage:
                  prevState?.preferences?.slippage ||
                  preferences?.slippage ||
                  DEFAULT_CACHED_SLIPPAGE,
              },
            };
          });
        } else {
          throw new Error("Failed to get tgUserId from window app");
        }
      }
    },
    [setState],
  );

  const _getTgUserIdFromCallback = useCallback(
    async (isTryLogin: boolean = true, isFromRedirect: boolean) => {
      if (!isFromRedirect) {
        if (isTryLogin) {
          return window.open(LOGIN_REDIRECT_URL, "_self");
        } else {
          return;
        }
      }

      const urlParams = new URLSearchParams(window.location.search || DEV_CALLBACK_URL);

      const _tgUserId = urlParams.get("tgUserId");
      const _tgToken = urlParams.get("token");

      if (!_tgUserId || !_tgToken) {
        console.debug("initDataUnsafe", {
          _tgUserId,
          _tgToken,
        });
        throw new Error("Failed to get tgUserId from Callback");
      }

      const userId = decodeURI(_tgUserId);

      if (userId) {
        const { user } = await tgAuth({
          userData: {
            user: {
              id: parseInt(userId),
            },
          },
        });

        const {
          id,
          tgUserId,
          tgUserName,
          tonAddress,
          solAddress,
          ethAddress,
          tgPhotoUrl,
          preferences,
        } = user;

        setState((prevState) => {
          return {
            ...prevState,
            userId: id,
            walletAddresses: {
              tonAddress,
              solAddress,
              ethAddress,
            },
            tgPhotoUrl,
            tgUserId,
            tgUserName,
            preferences: {
              ...preferences,
              slippage:
                prevState?.preferences?.slippage ||
                preferences?.slippage ||
                DEFAULT_CACHED_SLIPPAGE,
            },
          };
        });
        // Remvoe Search Params
        window.location.href = window.location.href.split("?")[0];
      } else {
        throw new Error("Failed to get tgUserId from callback");
      }
    },
    [setState],
  );

  const _checkIsRedirectedFromCallback = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search || DEV_CALLBACK_URL);

    const _tgUserId = urlParams.get("tgUserId");
    const _tgToken = urlParams.get("token");
    return !!_tgUserId && !!_tgToken;
  }, [setState]);

  const checkIsFromMiniApp = useCallback(() => {
    let userId = false;
    if (window.Telegram.WebApp) {
      // Initialize the Web App
      window.Telegram.WebApp.ready();
      console.debug(
        "window.Telegram.WebApp.initDataUnsafe: ",
        window.Telegram.WebApp.initDataUnsafe,
      );

      // Get user information
      const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
      const user = initDataUnsafe?.user;
      userId = user?.id;
    }
    return !!userId;
  }, [setState]);

  const setSlippage = useCallback(
    (slippage: number) => {
      setState((prevState) => {
        return {
          ...prevState,
          preferences: {
            ...prevState?.preferences,
            slippage,
          },
        };
      });
    },
    [setState],
  );

  const createWallet = useCallback(async () => {
    try {
      const result = await axiosService.createWallet();

      if (result) {
        setState((prevState) => {
          return {
            ...prevState,
            walletAddresses: {
              tonAddress: result.tonAddress,
              ethAddress: result.ethAddress,
              solAddress: result.solAddress,
            },
            tgPhotoUrl: result.tgPhotoUrl,
            tgUserId: result.tgUserId,
            tgUserName: result.tgUserName,
          };
        });
        return result;
      }
    } catch (error) {
      console.error("Failed to create wallet: ", error);
      throw error;
    }
  }, [setState]);

  const handleOnboard = () => {
    if (skipOnboarding) return;

    localStorage.setItem("isOnboarded", "true");
    setSkipOnboarding(true);
  };

  return {
    ...state,
    skipOnboarding,
    hasWallet,
    createWallet,
    getTgUserIdV2,
    logoutTgUserV2,
    setSlippage,
    checkIsFromMiniApp,
    handleOnboard,
  };
}

export default useUserStoreV2;
