import { useEffect } from "react";
import { JettonPrice } from "types";

export const useWsPriceData = ({
  chain,
  masterAddress,
  onPriceData,
}: {
  chain: string;
  masterAddress: string;
  onPriceData: (priceData: JettonPrice) => void;
}) => {
  useEffect(() => {
    if (!masterAddress) {
      return;
    }

    const ws = new WebSocket("wss://api.cabal.town/v1/wss");

    ws.onopen = () => {
      console.log("Connected to WebSocket server");

      // Subscribe to the "price" message type
      ws.send(
        JSON.stringify({
          action: "subscribe",
          type: "price",
          tokenAddress: masterAddress,
          chain,
        }),
      );
    };

    ws.onmessage = (event) => {
      if (typeof event.data !== "string") {
        return console.log("event.data is not a string");
      }

      const data = JSON.parse(event.data);
      if ("price" in data && "updatedAt" in data) {
        onPriceData(data);
      }
    };

    ws.onclose = () => {
      console.log("Disconnected from WebSocket server");
    };

    return () => {
      ws.close();
    };
  }, [masterAddress, onPriceData]);
};
