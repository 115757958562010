import { styled } from "@mui/material";

export const OPTContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8.6px",
  borderRadius: "16px",
  maxWidth: "100%",
  width: "fit-content",
  height: "64px",
  background: theme.palette.surface["container-bg"],
  border: `1px solid ${theme.palette.border.dark}`,
  padding: "8px",
}));

export const InputElement = styled("input")(({ theme }) => ({
  borderRadius: "7.38px",
  height: "48px",
  width: "40px",
  border: `1px solid ${theme.palette.border.dark}`,
  background: theme.palette.surface.silver,
  textAlign: "center",
  color: theme.palette.text.secondary,
  caretColor: theme.palette.text.secondary,
  padding: "0",
  "&:focus": {
    outlineWidth: 0,
  },
  ...theme.typography["title-h2"],
}));
