import { Box, styled } from "@mui/material";
import { HeaderToast } from "components/header/headerToast";
import useToastStore from "store/toast-store/useToastStore";

export const FullPageWrapperContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  background: theme.palette.surface.bg,
  zIndex: 2,

  overflowY: "auto",
  overflowX: "hidden",

  "-ms-overflow-style": "none" /* Internet Explorer 10+ */,
  "scrollbar-width": "none" /* Firefox */,

  "*::-webkit-scrollbar": {
    display: "none",
  },
  "*::-webkit-scrollbar-track": {
    display: "none",
  },
  "*::-webkit-scrollbar-thumb": {
    display: "none",
  },
}));

const FullPageWrapper = ({ children }: { children: React.ReactNode }) => {
  const { toastState, resetToast } = useToastStore();
  return (
    <FullPageWrapperContainer>
      <Box
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}>
        <HeaderToast
          isOpen={!!toastState.message}
          variant={toastState.variant}
          message={toastState.message}
          onClose={resetToast}
        />
        {children}
      </Box>
    </FullPageWrapperContainer>
  );
};

export default FullPageWrapper;
